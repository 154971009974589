import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Bars3Icon, HomeIcon, ChartPieIcon, XMarkIcon, ClipboardDocumentCheckIcon, Cog6ToothIcon, CircleStackIcon } from '@heroicons/react/24/outline';
import KanaryLogo from '../../assets/images/kanarys-logo-light.svg';
import { Link as RouterLink } from 'react-router-dom';
import { Tab } from '@headlessui/react';
import Products from '../Elements/Dashboard/Products';

import bindClassNames from 'classnames/bind';
import styles from './index.module.scss';
const cx = bindClassNames.bind(styles);

const Dashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(2);

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className={cx('mobile_wrapper')} onClose={setSidebarOpen}>
          <Transition.Child as={Fragment} enter="transition-opacity ease-linear duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity ease-linear duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className={cx('dim_effect')} />
          </Transition.Child>

          <div className={cx('mobile_menu')}>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full">
              <Dialog.Panel className={cx('mobile_nav')}>
                <Transition.Child as={Fragment} enter="ease-in-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
                  <div className={cx('hamburger_wrapper')}>
                    <button type="button" className={cx('hamburger')} onClick={() => setSidebarOpen(false)}>
                      <span className={cx('accessible')}>Close sidebar</span>
                      <XMarkIcon className={cx('hamburger_icon')} aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className={cx('mobile_nav_content')}>
                  <div className={cx('mobile_nav_logo')}>
                    <RouterLink to="/dashboard">
                      <img className={cx('image')} src={KanaryLogo} alt="Kanarys Logo" />
                    </RouterLink>
                  </div>
                  <nav className={cx('mobile_nav_list')}>
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={cx('mobile_nav_item', {
                          mobile_nav_item_active: item.current
                        })}>
                        <item.icon
                          className={cx('mobile_nav_icon', {
                            mobile_nav_icon_active: item.current
                          })}
                          aria-hidden="true"
                        />
                        {item.name}
                      </a>
                    ))}
                  </nav>
                </div>

                <footer className={cx('footer')}>
                  <a href="#" className={cx('mobile_profile_anchor')}>
                    <div className={cx('mobile_profile_content')}>
                      <div>
                        <img className={cx('mobile_profile_image')} src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
                      </div>
                      <div className="ml-3">
                        <p className={cx('mobile_profile_name')}>Tom Cook</p>
                        <p className={cx('mobile_profile_view')}>View profile</p>
                      </div>
                    </div>
                  </a>
                </footer>
              </Dialog.Panel>
            </Transition.Child>
            <div className={cx('shrink')}>{/* Force sidebar to shrink to fit close icon */}</div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className={cx('desktop_wrapper')}>
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className={cx('desktop_content')}>
          <div className={cx('desktop_nav')}>
            <div className={cx('desktop_nav_logo')}>
              <RouterLink to="/dashboard">
                <img className={cx('desktop_nav_image')} src={KanaryLogo} alt="Kanarys Logo" />
              </RouterLink>
            </div>
            <nav className={cx('desktop_nav_lists')}>
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className={cx('desktop_nav_item', {
                    active: item.current
                  })}>
                  <item.icon
                    className={cx('desktop_nav_icon', {
                      active: item.current
                    })}
                    aria-hidden="true"
                  />
                  {item.name}
                </a>
              ))}
            </nav>
          </div>
          <div className={cx('desktop_footer')}>
            <div>
              <ul className={cx('desktop_footer_list')}>
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
                <li>
                  <a href="#">Terms of Use</a>
                </li>
                <li>
                  <a href="#">Contact Us</a>
                </li>
                <li>
                  <a href="#">FAQ</a>
                </li>
              </ul>
            </div>
            <div>
              <p className={cx('copyright')}>&copy; 2023 Kanarys</p>
            </div>
          </div>
        </div>
      </div>
      <div className={cx('main_content')}>
        <div className={cx('main_bar')}>
          <button type="button" className={cx('bar_button')} onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className={cx('main')}>
          <div className={cx('main_helper_pad')}>
            <div className={cx('main_heading')}>
              <h1 className={cx('main_heading_title')}>Dashboard</h1>
            </div>

            <div className={cx('tab_wrapper')}>
              <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                <Tab.List className={cx('tab_list')}>
                  {Tabs.map((tab, index) => (
                    <Tab
                      key={index}
                      className={cx('tab_item', {
                        active: index === selectedIndex
                      })}>
                      {tab.title}
                    </Tab>
                  ))}
                </Tab.List>
                <Tab.Panels>
                  <Tab.Panel>
                    <div className={cx('tab_content')}>
                      <span className={cx('osa')}>OSA</span>
                      <span>Self-Expression</span>
                      <span>Employee Pulse</span>
                      <span>Employee Sentiment</span>
                    </div>
                  </Tab.Panel>
                  <Tab.Panel>Content 2</Tab.Panel>
                  <Tab.Panel>
                    <Products />
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;

const navigation = [
  { name: 'Dashboard', href: '#', icon: HomeIcon, current: true },
  { name: 'Projects', href: '#', icon: ChartPieIcon, current: false },
  { name: 'HRIS', href: '#', icon: CircleStackIcon, current: false },
  { name: 'Settings', href: '#', icon: Cog6ToothIcon, current: false }
];

const Tabs = [
  {
    title: 'Profile'
  },
  {
    title: 'Admin'
  },
  {
    title: 'Products'
  }
];
