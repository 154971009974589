import React from 'react';
import Lottie from 'react-lottie';
import AgreementLottie from '../../../assets/lotties/agreement.json';
import KanarysLogo from '../../../assets/images/kanarys-logo.png';
import { Link as RouterLink } from 'react-router-dom';

import bindClassNames from 'classnames/bind';
import styles from './index.module.scss';
import { useCmsPageQuery } from '../../../store';
import { Backdrop, CircularProgress } from '@material-ui/core';
import Errors from '../../Errors';

const cx = bindClassNames.bind(styles);

const termsOfUse = () => {
  const { data, error, isLoading } = useCmsPageQuery(2);

  if (isLoading) {
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else if (error) {
    return <Errors error={error} />;
  } else {
    return (
      <div className={cx('gradient')}>
        <div className={cx('wrapper')}>
          <div className={cx('tou_head')}>
            <img src={KanarysLogo} alt="Kanarys Logo" />
            <h1 className={cx('head')}>Terms of Use</h1>
            <span>
              <p className={cx('span')}>
                Last updated:{' '}
                {data &&
                  data.updatedAt &&
                  new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit'
                  }).format(new Date(data.updatedAt))}
              </p>
            </span>
          </div>

          <section className={cx('tou_body')}>
            <div>
              <nav>
                <ul className={cx('tou_list')}>
                  <li>
                    <RouterLink to="/terms-of-use">Terms of Use</RouterLink>
                  </li>
                  <li>
                    <RouterLink to="/terms-of-use-organizations" k>
                      Organizations
                    </RouterLink>
                  </li>
                </ul>
              </nav>
              <aside className={cx('aside')}>
                <ul className={cx('items')}>
                  {data.pageSections.map((item) => (
                    <li key={item.id} className={cx('item')}>
                      <a href={`#${item.id}`} className={cx('link')}>
                        {item.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </aside>
            </div>

            <main className={cx('main')}>
              {data.pageSections.map((item) => (
                <article key={item.id}>
                  <h2 id={item.id} className={cx('content_head')}>
                    {item.title}
                  </h2>
                  <div className={cx('content')} dangerouslySetInnerHTML={{ __html: item.content }} />
                </article>
              ))}
            </main>
          </section>

          <div className={cx('lottie')}>
            <div className={cx('lottie_wrapper')}>
              <Lottie
                isClickToPauseDisabled={true}
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: AgreementLottie,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                  }
                }}
              />
            </div>
            <p>Thank you for reading our Terms of Use.</p>
          </div>
        </div>
      </div>
    );
  }
};

export default termsOfUse;
