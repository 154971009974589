import { Typography, FormControl, Select, MenuItem, FormHelperText, FormGroup } from '@material-ui/core';
import QuestionText from './questionText';
import { Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { useContext } from 'react';
import { LanguageContext } from '../../contexts/LanguageContext';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const SelectInput = ({ id, name, control, infoText, required, level, questionData, currentQuestionIndex, handleChange, answeredData, ...rest }) => {
  const { dictionary } = useContext(LanguageContext);
  const classes = useStyles();
  questionData.formAnswers.sort((a, b) => parseFloat(a.rank) - parseFloat(b.rank));

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Typography style={{ alignItems: 'center' }}>
        <QuestionText surveyFormQuestion={questionData} infoText={infoText} level={level} currentQuesIndex={currentQuestionIndex} />
      </Typography>
      <FormGroup className={classes.formControl}>
        <Controller
          name={name}
          control={control}
          defaultValue={answeredData && answeredData?.formAnswers ? answeredData?.formAnswers : ''}
          render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
            <>
              <Select
                value={value}
                onChange={(event) => {
                  event.preventDefault();
                  onChange(event.target.value);
                  handleChange({ id: id, surveyFormQuestionId: questionData.id, level: level, value: questionData.formAnswers.filter((item) => item.id == event.target.value) });
                }}>
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {questionData.formAnswers.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={!!error}>{error ? error.message : null}</FormHelperText>
            </>
          )}
          rules={{
            required: {
              value: required,
              message: dictionary.surveyPopup.requiredQuestion
            }
          }}
        />
      </FormGroup>
    </FormControl>
  );
};

export default SelectInput;
