import React from 'react';
import { Typography, Slider, FormControl } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import QuestionText from './questionText';

// eslint-disable-next-line react/prop-types
export const SliderInput = ({ name, infoText, required, level, control, questionData, currentQuestionIndex, handleChange, answeredData }) => {
  const handleSliderChange = (event, newValue) => {
    handleChange(newValue);
    return newValue;
  };
  return (
    <FormControl component="fieldset" fullWidth>
      <Typography variant="h6" style={{ alignItems: 'center' }}>
        <QuestionText surveyFormQuestion={questionData} level={level} infoText={infoText} currentQuesIndex={currentQuestionIndex} />
      </Typography>
      <Controller
        name={name}
        control={control}
        defaultValue={answeredData ? answeredData?.formAnswers : 3}
        render={({ field: { onChange: handleChange, value }, fieldState, formState }) => <Slider value={value} onChange={(event, value) => handleChange(handleSliderChange(event, value))} valueLabelDisplay="auto" min={0} max={10} step={1} />}
      />
    </FormControl>
  );
};
