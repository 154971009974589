import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import RadioInput from './radioInput';
import CheckboxInput from './checkboxInput';
import SelectInput from './selectInput';
import TextInput from './textInput';
import MultiLineOpenEnded from './multiLineOpenEnded';
import { RankingInput } from './rankingInput';
import { DateTimeInput } from './dateTimeInput';
import { FileUploadInput } from './fileUploadInput';
import MatrixInput from './matrixInput';
import { SliderInput } from './sliderInput';
import SurveyCampaignContext from '../../contexts/SurveyCampaignContext';
import { generateKey, isAnswerQuestionMatched4rth } from './helpers';
import OtherTextInput from './otherTextInput';
import { LanguageContext } from '../../contexts/LanguageContext';
import TextPercentageInput from './textPercentageInput';
import TextNumberInput from './textNumberInput';
import useSurveyCampaignContext from '../../hooks/use-survey-campaign-context';

// eslint-disable-next-line react/prop-types
const SurveyCampaign4rthLevelForm = ({ question, currentQuestion, methods, catId, prefilledAnswers }) => {
  const { setSelectedAnswer, selectedAnswer4rthLevel, setSelectedAnswer4rthLevel } = useSurveyCampaignContext();
  const { dictionary } = useContext(LanguageContext);
  const { control, getValues } = methods;
  let answeredData = null;
  if (prefilledAnswers) {
    answeredData = prefilledAnswers[question.id];
    if (prefilledAnswers[question.id]?.formAnswers) {
      answeredData.formAnswers = prefilledAnswers[question.id].formAnswers;
    }
    if (prefilledAnswers[question.id]?.otherAnswerText) {
      answeredData.otherAnswerText = prefilledAnswers[question.id].otherAnswerText;
    }
  }

  let inputFieldName = `questions[${currentQuestion}][${question.id}]`;
  if (catId) {
    inputFieldName = `questions[${question.id}]`;
  }
  const handleRadioCheckboxChange = (selectedValue) => {
    if (selectedValue !== null) {
      const isPresent = selectedAnswer4rthLevel.find((item) => item.id === selectedValue.id);
      if (isPresent) {
        const remaining = selectedAnswer4rthLevel.filter((item) => item.id !== selectedValue.id);
        setSelectedAnswer4rthLevel(() => [...remaining, selectedValue]);
      } else {
        setSelectedAnswer4rthLevel((prevItems) => [...prevItems, selectedValue]);
      }
    }
  };

  const handleChange = (values) => {
    const fldVal = getValues(`${inputFieldName}.formAnswers`) ? getValues(`${inputFieldName}.formAnswers`) : values.value;
    setSelectedAnswer4rthLevel((prevItems) => [...prevItems, { id: question.id, surveyFormQuestionId: question.surveyFormQuestion.id, level: question.level, value: fldVal }]);
  };

  const handleFileChange = (values) => {
    setSelectedAnswer4rthLevel((prevItems) => [
      ...prevItems,
      {
        id: question.id,
        surveyFormQuestionId: question.surveyFormQuestion.id,
        level: question.level,
        filedata: getValues(`${inputFieldName}.fileTypeAnswer`)
      }
    ]);
  };

  if (!question.hasOwnProperty('surveyFormQuestion')) return null;

  let hasOtherOption = null;
  let hasAnsweredValue = null;

  const fieldVal = getValues(`${inputFieldName}`);
  if (fieldVal && fieldVal.formAnswers) {
    hasAnsweredValue = fieldVal;
  } else {
    hasAnsweredValue = answeredData;
  }

  if (question && typeof hasAnsweredValue !== 'undefined') {
    if (Array.isArray(hasAnsweredValue?.formAnswers) && hasAnsweredValue?.formAnswers.length > 0) {
      hasOtherOption = hasAnsweredValue.formAnswers.find((item) => item.other);
    } else {
      const currentAnswered = question.surveyFormQuestion.formAnswers.find((item) => item.id == parseInt(hasAnsweredValue?.formAnswers));
      hasOtherOption = currentAnswered;
    }
  }

  return (
    <>
      <div id={`ques_${question.id}`} key={generateKey(question)}>
        {(question.surveyFormQuestion?.type === 'choice-unique-expanded' || question.surveyFormQuestion?.type === 'choice-true-false') && (
          <RadioInput
            id={question.id}
            name={`${inputFieldName}.formAnswers`}
            infoText={question.info}
            required={question.isRequired ? { required: dictionary.surveyPopup.requiredQuestion } : ''}
            level={question.level}
            control={control}
            questionData={question.surveyFormQuestion}
            currentQuestionIndex={currentQuestion}
            handleChange={handleRadioCheckboxChange}
            answeredData={answeredData}
          />
        )}

        {(question.surveyFormQuestion?.type === 'choice-multiple-expanded' || question.surveyFormQuestion?.type === 'choice-multiple-collapsed') && (
          <CheckboxInput
            id={question.id}
            name={`${inputFieldName}.formAnswers`}
            infoText={question.info}
            required={question.isRequired ? { required: dictionary.surveyPopup.requiredQuestion } : ''}
            level={question.level}
            maxSelection={question.maxAnswerSelection}
            control={control}
            value={getValues(`${inputFieldName}.formAnswers`)}
            questionData={question.surveyFormQuestion}
            currentQuestionIndex={currentQuestion}
            handleChange={handleRadioCheckboxChange}
            answeredData={answeredData}
          />
        )}

        {question.surveyFormQuestion?.type === 'textarea' && question.typeRestriction === 'percentage' && (
          <TextPercentageInput
            id={question.id}
            name={`${inputFieldName}.formAnswers`}
            infoText={question.info}
            required={question.isRequired ? { required: dictionary.surveyPopup.requiredQuestion } : ''}
            level={question.level}
            control={control}
            questionData={question.surveyFormQuestion}
            currentQuestionIndex={currentQuestion}
            handleChange={handleChange}
            answeredData={answeredData}
          />
        )}

        {(question.surveyFormQuestion?.type === 'textarea' || question.surveyFormQuestion?.type === 'text') && question.typeRestriction === 'percentage' && (
          <TextPercentageInput
            id={question.id}
            name={`${inputFieldName}.formAnswers`}
            infoText={question.info}
            required={question.isRequired}
            level={question.level}
            control={control}
            questionData={question.surveyFormQuestion}
            currentQuestionIndex={currentQuestion}
            handleChange={handleChange}
            answeredData={answeredData}
          />
        )}

        {(question.surveyFormQuestion?.type === 'textarea' || question.surveyFormQuestion?.type === 'text') && question.typeRestriction === 'number' && (
          <TextNumberInput
            id={question.id}
            name={`${inputFieldName}.formAnswers`}
            infoText={question.info}
            required={question.isRequired}
            level={question.level}
            control={control}
            questionData={question.surveyFormQuestion}
            currentQuestionIndex={currentQuestion}
            handleChange={handleChange}
            answeredData={answeredData}
          />
        )}

        {(question.surveyFormQuestion?.type === 'textarea' || question.surveyFormQuestion?.type === 'text') && (question.typeRestriction === 'text' || question.typeRestriction === '') && (
          <TextInput
            id={question.id}
            name={`${inputFieldName}.formAnswers`}
            infoText={question.info}
            required={question.isRequired ? { required: dictionary.surveyPopup.requiredQuestion } : ''}
            level={question.level}
            control={control}
            questionData={question.surveyFormQuestion}
            currentQuestionIndex={currentQuestion}
            handleChange={handleChange}
            answeredData={answeredData}
          />
        )}

        {question.surveyFormQuestion?.type === 'ranking' && (
          <RankingInput
            id={question.id}
            name={`${inputFieldName}.formAnswers`}
            infoText={question.info}
            required={question.isRequired}
            level={question.level}
            control={control}
            value={getValues(`${inputFieldName}.formAnswers`)}
            questionData={question.surveyFormQuestion}
            currentQuestionIndex={currentQuestion}
            handleChange={handleChange}
            answeredData={answeredData}
          />
        )}

        {question.surveyFormQuestion?.type === 'file-upload' && (
          <FileUploadInput
            id={question.id}
            name={`${inputFieldName}.fileTypeAnswer`}
            infoText={question.info}
            level={question.level}
            control={control}
            required={question.isRequired}
            questionData={question.surveyFormQuestion}
            currentQuestionIndex={currentQuestion}
            handleFileChange={handleFileChange}
            answeredData={answeredData}
          />
        )}

        {question.surveyFormQuestion?.type === 'date-time' && (
          <DateTimeInput
            id={question.id}
            name={`${inputFieldName}.formAnswers`}
            infoText={question.info}
            required={question.isRequired}
            level={question.level}
            control={control}
            questionData={question.surveyFormQuestion}
            currentQuestionIndex={currentQuestion}
            handleChange={handleChange}
            answeredData={answeredData}
          />
        )}

        {question.surveyFormQuestion?.type === 'multi-line-open-ended' && (
          <MultiLineOpenEnded
            id={question.id}
            name={`${inputFieldName}.formAnswers`}
            infoText={question.info}
            level={question.level}
            required={question.isRequired}
            control={control}
            questionData={question.surveyFormQuestion}
            currentQuestionIndex={currentQuestion}
            handleChange={handleChange}
            answeredData={answeredData}
          />
        )}

        {question.surveyFormQuestion?.type === 'matrix' && (
          <MatrixInput
            id={question.id}
            name={`${inputFieldName}.formAnswers`}
            infoText={question.info}
            required={question.isRequired}
            level={question.level}
            control={control}
            questionData={question.surveyFormQuestion}
            currentQuestionIndex={currentQuestion}
            handleChange={handleChange}
            answeredData={answeredData}
          />
        )}

        {question.surveyFormQuestion?.type === 'slider' && (
          <SliderInput
            id={question.id}
            name={`${inputFieldName}.formAnswers`}
            infoText={question.info}
            required={question.isRequired}
            level={question.level}
            control={control}
            questionData={question.surveyFormQuestion}
            currentQuestionIndex={currentQuestion}
            handleChange={handleChange}
            answeredData={answeredData}
          />
        )}
      </div>
      {isAnswerQuestionMatched4rth(selectedAnswer4rthLevel, question.surveyFormQuestion) || (hasOtherOption && hasOtherOption.other) ? (
        <Grid key={`other_${question.id}_${question.level}_${question.surveyFormQuestion.id}`} container spacing={3} style={{ paddingLeft: 35, paddingBottom: 20, width: '98%' }}>
          <OtherTextInput
            id={question.id}
            name={`${inputFieldName}.otherAnswerText`}
            infoText={question.info}
            required={question.isRequired}
            control={control}
            typeRestriction={question.typeRestriction}
            questionData={question.surveyFormQuestion}
            currentQuestionIndex={currentQuestion}
            handleChange={handleChange}
            answeredData={answeredData}
          />
        </Grid>
      ) : null}
    </>
  );
};

export default SurveyCampaign4rthLevelForm;
