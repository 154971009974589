import { useContext } from 'react';
import { TextField, FormControl, FormGroup, Typography } from '@material-ui/core';
import QuestionText from './questionText';
import { Controller } from 'react-hook-form';
import { LanguageContext } from '../../contexts/LanguageContext';
import useSurveyCampaignContext from '../../hooks/use-survey-campaign-context';

const TextNumberInput = ({ id, label, name, infoText, control, required, level, questionData, currentQuestionIndex, handleChange, answeredData }) => {
  const { dictionary } = useContext(LanguageContext);
  const { setCurrentElement } = useSurveyCampaignContext();

  const validation = (value) => {
    const matches = value.match(/^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/);
    return matches?.length > 0 || 'Not a Number';
  };

  return (
    <FormControl component="fieldset" fullWidth>
      <Typography variant="h6" style={{ alignItems: 'center' }}>
        {<QuestionText surveyFormQuestion={questionData} infoText={infoText} level={level} currentQuesIndex={currentQuestionIndex} />}
      </Typography>
      <FormGroup>
        <Controller
          {...{
            name: name,
            control: control,
            rules: {
              required: {
                value: required,
                message: dictionary.surveyPopup.requiredQuestion
              },
              min: { value: 0, message: 'Minmum value should be 0.' },
              validate: validation
            },
            defaultValue: answeredData ? answeredData?.formAnswers : '',
            render: ({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                size="small"
                onChange={onChange}
                onBlur={(e) => {
                  setCurrentElement(e.target.parentElement.parentElement.parentElement.parentElement.parentElement);
                  handleChange({ id: id, surveyFormQuestionId: questionData.id, level: level, value: e.target.value });
                }}
                value={value}
                placeholder={questionData.placeholder}
                fullWidth
                label={label}
                error={!!error}
                helperText={error ? error.message : null}
                variant="outlined"
              />
            )
          }}
        />
      </FormGroup>
    </FormControl>
  );
};

export default TextNumberInput;
