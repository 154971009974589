import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Card, CardContent, CardActions, Grid, Typography, Dialog, DialogActions, List, ListItem, ListItemText, Paper, Button, Backdrop, CircularProgress } from '@material-ui/core';
import { DialogTitle, DialogContent } from '../common/commonDialogComponents';
import SurveyCampaignContext from '../../contexts/SurveyCampaignContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import { useForm } from 'react-hook-form';
import SurveyCampaign1stLevelForm from '../common/SurveyCampaign1stLevelForm';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Errors from '../Errors';
import { useFetchPopupSurveyQuestionsQuery, useSubmitPopupSurveyQuestionsMutation } from '../../store';

const SurveyCampaignDialog = (props) => {
  const { currentQuestion, setCurrentQuestion, questionsAll, setQuestionsAll, unattendedQuestions, setUnattendedQuestions } = useContext(SurveyCampaignContext);

  const { data: retrieved, error, isLoading } = useFetchPopupSurveyQuestionsQuery(props.match.params);
  const [submitPopup, { data: submitted }] = useSubmitPopupSurveyQuestionsMutation();

  const { dictionary } = useContext(LanguageContext);
  const [questions1stLevel, setQuestions1stLevel] = useState([]);
  const [submitAsItIs, setSubmitAsItIs] = useState(false);
  const [question, setQuestion] = useState('');
  const [formValues, setFormValues] = useState([]);
  const [buttonType, setButtonType] = useState('');
  const methods = useForm({ defaultValues: { questions: [] }, shouldUnregister: true });
  const { handleSubmit, setValue, getValues, reset } = methods;
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (retrieved) {
      setQuestionsAll(retrieved['hydra:member']);
    }
  }, [retrieved]);

  useEffect(() => {
    if (questionsAll.length > 0) {
      const firstLevelQuestionsWithDuplicate = questionsAll.filter(
        (question) =>
          question.level === 0 &&
          question.parentSurveyFormQuestion === null &&
          (question.surveyFormQuestion.formAnswers.length > 0 ||
            question.surveyFormQuestion.dateTimeTypeAnswer !== null ||
            question.surveyFormQuestion.fileTypeAnswer !== null ||
            question.surveyFormQuestion.sliderLabelMax !== null ||
            question.surveyFormQuestion.sliderLabelMin !== null)
      );
      const firstLevelQuestions = Array.from(new Set(firstLevelQuestionsWithDuplicate));
      firstLevelQuestions.sort((a, b) => parseFloat(a.rank) - parseFloat(b.rank));
      setCurrentQuestion(0);
      setQuestions1stLevel(firstLevelQuestions);
    }
    reset();
    setFormValues([]);
  }, [questionsAll]);

  useEffect(() => {
    if (questions1stLevel.length > 0) {
      setQuestion(questions1stLevel[0]);
    }
  }, [questions1stLevel]);

  const handleNext = (data) => {
    if (typeof formValues[currentQuestion] !== 'undefined') {
      let updateValues = formValues;
      updateValues[currentQuestion] = data.questions[currentQuestion];
      setFormValues(updateValues);
    } else {
      setFormValues((prevItems) => [...prevItems, data.questions[currentQuestion]]);
    }

    const nextQuetion = currentQuestion + 1;
    if (nextQuetion < questions1stLevel.length) {
      setQuestion(questions1stLevel[nextQuetion]);
      setCurrentQuestion(nextQuetion);
    }
  };

  const handlePrevious = () => {
    const previousQuetion = currentQuestion - 1;
    if (previousQuetion >= 0) {
      setQuestion(questions1stLevel[previousQuetion]);
      setCurrentQuestion(previousQuetion);
    }

    if (formValues.length > 0) {
      formValues.forEach((element, index) => {
        element.forEach((elmnt, key) => {
          if (elmnt.hasOwnProperty('fileTypeAnswer') && typeof elmnt.fileTypeAnswer !== 'undefined') {
            setValue(`questions[${index}][${key}].fileTypeAnswer`, elmnt.fileTypeAnswer);
          } else if (elmnt.hasOwnProperty('otherAnswerText') && typeof elmnt.otherAnswerText !== 'undefined') {
            setValue(`questions[${index}][${key}].otherAnswerText`, elmnt.otherAnswerText);
          } else if (elmnt.hasOwnProperty('formAnswers') && typeof elmnt.formAnswers !== 'undefined') {
            setValue(`questions[${index}][${key}].formAnswers`, elmnt.formAnswers);
            console.log(getValues(`questions[${index}][${key}].formAnswers`));
          }
        });
      });
    }
  };

  const saveAndExit = (data) => {
    let formData = new FormData();
    let finalFormData = formValues;
    setButtonType('Save & Exit');
    if (typeof finalFormData[currentQuestion] !== 'undefined') {
      finalFormData[currentQuestion] = data.questions[currentQuestion];
    } else {
      finalFormData = [...finalFormData, data.questions[currentQuestion]];
    }
    formData.append(`button`, 'save & exit');
    if (finalFormData.length > 0) {
      finalFormData.forEach((element) => {
        element.forEach((elmnt, key) => {
          if (elmnt.hasOwnProperty('fileTypeAnswer') && typeof elmnt.fileTypeAnswer !== 'undefined') {
            formData.append(`${key}`, elmnt.fileTypeAnswer[0]);
          } else if (elmnt.hasOwnProperty('formAnswers') && typeof elmnt.formAnswers !== 'undefined') {
            formData.append(`${key}`, JSON.stringify(elmnt));
          }
        });
      });
    }
    submitPopup({ params: props.match.params, formData: formData });
    setQuestionsAll([]);
  };

  useEffect(() => {
    if (submitted) {
      const unattended = props.submitted['hydra:member'].filter(
        (item) =>
          item.level === 0 &&
          item.parentSurveyFormQuestion === null &&
          (item.surveyFormQuestion.formAnswers.length > 0 ||
            item.surveyFormQuestion.dateTimeTypeAnswer !== null ||
            item.surveyFormQuestion.fileTypeAnswer !== null ||
            item.surveyFormQuestion.sliderLabelMax !== null ||
            item.surveyFormQuestion.sliderLabelMin !== null)
      );
      setUnattendedQuestions(unattended);
      if (unattended.length === 0) {
        setQuestions1stLevel([]);
      }
    }
  }, [submitted]);

  const submitAnswer = (data) => {
    let formData = new FormData();
    let finalFormData = formValues;
    setButtonType('submit');
    if (typeof finalFormData[currentQuestion] !== 'undefined') {
      finalFormData[currentQuestion] = data.questions[currentQuestion];
    } else {
      finalFormData = [...finalFormData, data.questions[currentQuestion]];
    }

    formData.append(`button`, 'submit');
    if (finalFormData.length > 0) {
      finalFormData.forEach((element) => {
        element.forEach((elmnt, key) => {
          if (elmnt.hasOwnProperty('fileTypeAnswer') && typeof elmnt.fileTypeAnswer !== 'undefined') {
            formData.append(`${key}`, elmnt.fileTypeAnswer[0]);
          } else if (elmnt.hasOwnProperty('formAnswers') && typeof elmnt.formAnswers !== 'undefined') {
            formData.append(`${key}`, JSON.stringify(elmnt));
          }
        });
      });
    }
    submitPopup({ params: props.match.params, formData: formData });
  };

  const goBack = () => {
    setQuestionsAll(submitted['hydra:member']);
    setUnattendedQuestions([]);
    setCurrentQuestion(0);
    setSubmitAsItIs(false);
    setOpen(true);
    setButtonType('');
  };

  const submitAsIs = () => {
    setSubmitAsItIs(true);
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      switch (event.key) {
        case 'ArrowRight':
          handleNext();
          break;

        case 'ArrowLeft':
          handlePrevious();
          break;
        default:
          break;
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [currentQuestion]);

  if (isLoading) {
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (error) {
    return (
      <Fragment>
        <Errors error={error} />

        {/* <Card style={{ alignItems: 'center' }}> */}
        {/*   <CardContent style={{ height: 540, overflow: 'auto', paddingLeft: 50, paddingRight: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}> */}
        {/*     <Paper elevation={0} style={{ lineHeight: '30px' }}> */}
        {/*       <Typography variant="h6" style={{ textAlign: 'center' }}> */}
        {/*         {error} */}
        {/*       </Typography> */}
        {/*     </Paper> */}
        {/*   </CardContent> */}
        {/* </Card> */}
      </Fragment>
    );
  }

  if (submitAsItIs) {
    return (
      <Card style={{ alignItems: 'center' }}>
        <CardContent style={{ height: 540, overflow: 'auto', paddingLeft: 50, paddingRight: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Paper elevation={0} style={{ lineHeight: '30px' }}>
            <Typography variant="h6" style={{ textAlign: 'center' }}>
              {dictionary.surveyPopup.thankyoumsg}
            </Typography>
          </Paper>
        </CardContent>
      </Card>
    );
  }

  if (questions1stLevel.length === 0 || buttonType === 'submit') {
    return (
      <Card style={{ alignItems: 'center' }}>
        <CardContent style={{ height: 540, overflow: 'auto', paddingLeft: 50, paddingRight: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Paper elevation={0} style={{ lineHeight: '30px' }}>
            <Typography variant="h6" style={{ textAlign: 'center' }}>
              {dictionary.surveyPopup.complitionmsg}
            </Typography>
          </Paper>
        </CardContent>
      </Card>
    );
  }

  if (unattendedQuestions.length > 0 && buttonType === 'Save & Exit') {
    return (
      <Card style={{ alignItems: 'center' }}>
        <CardContent style={{ height: 540, overflow: 'auto' }}>
          <Grid container spacing={3} style={{ padding: '10px 20px' }}>
            <Typography variant="h6">
              {dictionary.surveyPopup.following} {unattendedQuestions.length} {dictionary.surveyPopup['question(s) not answered yet']}
            </Typography>
          </Grid>
          <List dense={true}>
            {unattendedQuestions.map((item, index) => (
              <ListItem key={item.id}>
                <ListItemText primary={`${index + 1}) ${item.surveyFormQuestion.label}`} />
              </ListItem>
            ))}
          </List>
        </CardContent>
        <CardActions style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #ddd' }}>
          <Button variant="contained" color="primary" size="small" onClick={goBack}>
            {dictionary.surveyPopup.yesGoBack}
          </Button>
          <Button variant="contained" color="primary" size="small" onClick={submitAsIs}>
            {dictionary.surveyPopup.submitAsIs}
          </Button>
        </CardActions>
      </Card>
    );
  }

  if (questionsAll.length > 0 && question.hasOwnProperty('surveyFormQuestion')) {
    return (
      <Dialog open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {dictionary.surveyPopup.workplaceDEIAssessment}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3} style={{ padding: '10px 20px' }}>
            <Typography variant="subtitle2">
              <span>
                {dictionary.surveyPopup.question} {currentQuestion + 1} {dictionary.surveyPopup.of}{' '}
              </span>
              {questions1stLevel.length}
            </Typography>
          </Grid>
          <div style={{ paddingTop: 20, width: 540, height: 540, overflow: 'auto' }}>
            <SurveyCampaign1stLevelForm question={question} currentQuestion={currentQuestion} methods={methods} />
          </div>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: currentQuestion + 1 == questions1stLevel.length ? 'center' : 'space-between', borderTop: '1px solid #ddd' }}>
          {questions1stLevel.length > 1 && (
            <Button variant="contained" color="primary" size="small" onClick={handlePrevious} disabled={currentQuestion === 0} startIcon={<NavigateBeforeIcon />}>
              {dictionary.surveyPopup.previous}
            </Button>
          )}
          {currentQuestion >= 1 && currentQuestion + 1 < questions1stLevel.length && (
            <Button variant="contained" color="primary" size="small" onClick={handleSubmit(saveAndExit)}>
              {dictionary.surveyPopup.savenexit}
            </Button>
          )}
          {currentQuestion + 1 === questions1stLevel.length && (
            <Button variant="contained" color="primary" size="small" onClick={handleSubmit(submitAnswer)}>
              {dictionary.surveyPopup.submit}
            </Button>
          )}
          {currentQuestion + 1 < questions1stLevel.length && (
            <Button variant="contained" color="primary" size="small" onClick={handleSubmit(handleNext)} endIcon={<NavigateNextIcon />}>
              {dictionary.surveyPopup.next}{' '}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  } else {
    return null;
  }
};

export default SurveyCampaignDialog;
