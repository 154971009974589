import React, { useEffect, useState, useContext } from 'react';
import SurveyCampaignContext from '../../contexts/SurveyCampaignContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import { Card, CardContent, CardActions, Grid, useMediaQuery, useTheme, Typography, Button, Backdrop, CircularProgress, List, ListItemText, Paper } from '@material-ui/core';
import MuiListItem from '@material-ui/core/ListItem';
import { useForm } from 'react-hook-form';
import SurveyCampaign1stLevelForm from '../common/SurveyCampaign1stLevelForm';
import SurveyModal from '../common/SurveyModal';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { split } from 'lodash';
import { getFirstLevelQuestions } from '../common/helpers';
import { useLocation } from 'react-router-dom';

import bindClassNames from 'classnames/bind';
import styles from './surveycampaignform.module.scss';
import { useFetchSurveyContributionsQuery, useFetchFormSurveyQuestionsQuery, useSubmitFormSurveyQuestionsMutation, useAssessmentLandingPageQuery } from '../../store';
const cx = bindClassNames.bind(styles);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}));

const ListItem = withStyles({
  root: {
    '&$selected': {
      backgroundColor: 'red',
      color: 'white',
      '& .MuiListItemIcon-root': {
        color: 'white'
      }
    },
    '&$selected:hover': {
      backgroundColor: 'purple',
      color: 'white',
      '& .MuiListItemIcon-root': {
        color: 'white'
      }
    },
    '&:hover': {
      backgroundColor: 'blue',
      color: 'white',
      '& .MuiListItemIcon-root': {
        color: 'white'
      }
    }
  },
  selected: {}
})(MuiListItem);

const SurveyCampaignForm = (props) => {
  const { currentQuestion, setCurrentQuestion, selectedAnswer, questionsAll, setQuestionsAll, currentElement, categories, setCategories } = useContext(SurveyCampaignContext);
  const { dictionary } = useContext(LanguageContext);
  const { data: surveyFormQuestions, error, isLoading } = useFetchFormSurveyQuestionsQuery(props.match.params);
  const { data: surveyContributions } = useFetchSurveyContributionsQuery(props.match.params);
  const { data: pinpointCampaign } = useAssessmentLandingPageQuery(props?.match?.params.campaignId);
  const [submitForm, result] = useSubmitFormSurveyQuestionsMutation();
  const [questions1stLevel, setQuestions1stLevel] = useState([]);
  const [unattendedQuestions, setUnattendedQuestions] = useState([]);
  const [formFieldValues, setFormFieldValues] = useState([]);
  const [submitAsItIs, setSubmitAsItIs] = useState(false);
  const [question, setQuestion] = useState('');
  const [prefilledAnswers, setPrefilledAnswers] = useState([]);
  const [checked, setChecked] = useState([1]);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [buttonType, setButtonType] = useState('');
  const [ccatIndx, setCcatIndx] = useState('');
  const [answerData, setAnswerData] = useState([]);
  const [contributionQuestions, setContributionQuestions] = useState([]);
  const methods = useForm();
  const [copied, setCopied] = useState('');
  const { handleSubmit, getValues } = methods;
  const [formSection, setFormSection] = useState(false);
  const [campaginSection, setCampaginSection] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.match(/\/form\//)) {
      setFormSection(true);
    } else if (location.pathname.match(/\/campaign\//)) {
      setCampaginSection(true);
    }
  }, [location]);

  useEffect(() => {
    if (surveyFormQuestions) {
      setQuestionsAll(surveyFormQuestions['hydra:member']);
    }
  }, [surveyFormQuestions]);

  useEffect(() => {
    if (questionsAll.length > 0) {
      let cats = [];
      const firstLevelQuestions = getFirstLevelQuestions(questionsAll);
      const newQuestionAll = JSON.parse(JSON.stringify(questionsAll));
      newQuestionAll.forEach((question) => {
        if (question.level === 0 && question.surveyFormQuestion?.category) {
          const cat = question.surveyFormQuestion.category;
          const categoryWiseQues = firstLevelQuestions.filter((item) => item.surveyFormQuestion.category.id === cat.id);
          const quesArr = categoryWiseQues.map((item) => item.id);
          cat.questions = quesArr;
          cats = [...cats, cat];
        }
      });
      const questionsCat = [...new Map(cats.filter((cat) => typeof cat !== 'undefined').map((item) => [item.id, item])).values()];
      questionsCat.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
      setCategories(questionsCat);
    }
  }, [questionsAll]);

  useEffect(() => {
    if (surveyContributions && categories.length > 0) {
      setContributionQuestions(surveyContributions['hydra:member']);
    }
  }, [categories]);

  useEffect(() => {
    if (contributionQuestions.length > 0) {
      let answers = [];
      const newContributionQuestions = JSON.parse(JSON.stringify(contributionQuestions));
      newContributionQuestions.forEach((item) => {
        item.contributionSteps.forEach((item1) => {
          item1.contributionQuestions.forEach((item2) => {
            answers = [...answers, item2];
          });
        });
      });
      setAnswerData(answers);
    }
  }, [contributionQuestions]);

  useEffect(() => {
    if (answerData.length > 0) {
      const newCategories = categories.map((item) => {
        return { ...item, questions: item.questions.filter((item) => !answerData.some((ansd) => ansd?.surveyCompanySurveyFormQuestion?.id === item)) };
      });
      setCategories(newCategories);
    }
  }, [answerData]);

  useEffect(() => {
    if (answerData.length > 0 && categories.length > 0) {
      const afterHashValue = queryString.parse(props.location.hash);
      setCurrentCategory(afterHashValue && afterHashValue.catid ? categories.find((cat) => cat.id === parseInt(afterHashValue.catid)) : categories[0]);
      let prefilledAnswer = [];
      answerData.forEach((answer) => {
        const ques = questionsAll.find((item) => item.id === answer?.surveyCompanySurveyFormQuestion?.id);
        if (ques && ques.id)
          switch (ques?.surveyFormQuestion.type) {
            case 'choice-unique-expanded':
            case 'choice-unique-collapsed':
            case 'choice-true-false':
              prefilledAnswer[`${answer.surveyCompanySurveyFormQuestion.id}`] = { formAnswers: `${answer?.formAnswer?.id}`, otherAnswerText: answer?.value };
              break;
            case 'multi-line-open-ended':
              if (answer?.contributionMultiLineOpenEndedAnswers && answer?.contributionMultiLineOpenEndedAnswers.length > 0) {
                prefilledAnswer[`${answer.surveyCompanySurveyFormQuestion.id}`] = { formAnswers: answer?.contributionMultiLineOpenEndedAnswers.map((item) => ({ answerText: item.answerText, id: item.formAnswer.id })) };
              }
              break;
            case 'date-time':
              prefilledAnswer[`${answer.surveyCompanySurveyFormQuestion.id}`] = {
                formAnswers: { id: answer?.contributionDateTimeAnswers?.dateTimeTypeAnswer.id, date: answer?.contributionDateTimeAnswers?.eventDate, time: answer?.contributionDateTimeAnswers.eventTime }
              };
              break;
            case 'ranking':
              prefilledAnswer[`${answer.surveyCompanySurveyFormQuestion.id}`] = { formAnswers: answer?.contributionRankingAnswers.map((item) => item.formAnswer) };
              break;
            case 'matrix':
              if (answer?.surveyContributionMatrixAnswers && answer?.surveyContributionMatrixAnswers.length > 0) {
                prefilledAnswer[`${answer.surveyCompanySurveyFormQuestion.id}`] = { formAnswers: answer?.surveyContributionMatrixAnswers.map((item) => ({ id: item.formAnswer.id, value: item.answerText })) };
              }
              break;
            case 'slider':
              prefilledAnswer[`${answer.surveyCompanySurveyFormQuestion.id}`] = { formAnswers: parseInt(answer.value) };
              break;
            case 'textarea':
            case 'text':
              prefilledAnswer[`${answer.surveyCompanySurveyFormQuestion.id}`] = { formAnswers: answer.value };
              break;
            case 'choice-multiple-expanded':
            case 'choice-multiple-collapsed':
              prefilledAnswer[`${answer.surveyCompanySurveyFormQuestion.id}`] = { formAnswers: answer?.formAnswers, otherAnswerText: answer?.value };
              break;
            case 'file-upload':
              prefilledAnswer[`${answer.surveyCompanySurveyFormQuestion.id}`] = { formAnswers: answer?.contributionFileUploadAnswers, type: 'fileUpload' };
              break;
            default:
              prefilledAnswer[`${answer.surveyCompanySurveyFormQuestion.id}`] = { formAnswers: '' };
              break;
          }
      });
      setPrefilledAnswers(prefilledAnswer);
    }
  }, [answerData]);

  useEffect(() => {
    if (currentCategory !== null && currentCategory.id && categories.length > 0) {
      const currentCat = currentCategory;
      currentCat.questions = currentCat.questions.filter((item) => !answerData.some((ansd) => ansd?.surveyCompanySurveyFormQuestion?.id === item));
      setCurrentCategory(currentCat);
    }
  }, [prefilledAnswers]);

  useEffect(() => {
    if (categories.length > 0) {
      const afterHashValue = queryString.parse(props.location.hash);
      if (afterHashValue && afterHashValue.catid) {
        setCurrentCategory(categories.find((cat) => cat.id === parseInt(afterHashValue.catid)));
      }
      if (!props.location.hash && currentCategory === null) {
        setCurrentCategory(categories[0]);
      }
    }
  }, [categories]);

  useEffect(() => {
    if (currentCategory !== null && currentCategory.id && categories.length > 0) {
      setCurrentQuestion(0);
      const firstLevelQuestions = getFirstLevelQuestions(questionsAll);
      const categoryWiseQues = firstLevelQuestions.filter((item) => item.surveyFormQuestion.category.id === currentCategory.id);
      setQuestions1stLevel(categoryWiseQues.length ? categoryWiseQues : []);
      setCcatIndx(categories.indexOf(currentCategory));
    }
  }, [currentCategory]);

  useEffect(() => {
    if (questions1stLevel.length > 0) {
      setQuestion(questions1stLevel[0]);
    }
  }, [questions1stLevel]);

  const isSelectedHasValue = (selectedAnswer) => {
    let isValue = false;
    if (selectedAnswer && selectedAnswer.hasOwnProperty('value')) {
      const answer = selectedAnswer.value;
      switch (typeof answer) {
        case 'string':
          isValue = answer !== '' ? true : false;
          break;
        case 'object':
          if (answer && Array.isArray(answer)) {
            isValue = answer.filter((item) => item.value !== '').length > 0 ? true : false;
          } else {
            isValue = answer.hasOwnProperty('id') ? true : false;
          }
          break;
        case 'number':
          isValue = answer ? true : false;
          break;
        default:
          isValue = false;
          break;
      }
      return isValue;
    } else if (selectedAnswer && selectedAnswer.hasOwnProperty('filedata')) {
      return true;
    }
  };

  useEffect(() => {
    if (selectedAnswer && selectedAnswer.id && currentCategory) {
      const currntCat = currentCategory;
      const currentSelection = getValues(`questions[${selectedAnswer.id}]`);
      if (currentSelection && (currentSelection.hasOwnProperty('formAnswers') || currentSelection.hasOwnProperty('fileTypeAnswer'))) {
        const remainQues = currntCat.questions.filter((item) => item !== selectedAnswer.id);
        if (isSelectedHasValue(selectedAnswer)) {
          currntCat.questions = [...remainQues];
        } else if (!isSelectedHasValue(selectedAnswer)) {
          currntCat.questions = [...remainQues, selectedAnswer.id];
        }
        const remainingCat = categories.filter((item) => item.id !== currentCategory.id);
        const newCategories = [...remainingCat, currntCat];
        newCategories.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
        setCategories(() => [...newCategories]);
      }
    }

    if (currentElement && currentElement.nextSibling) {
      currentElement.nextSibling.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [selectedAnswer]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    setCurrentCategory(value);
  };

  const handleNext = () => {
    const nextQuetion = currentQuestion + 1;
    if (nextQuetion < questions1stLevel.length) {
      setQuestion(questions1stLevel[nextQuetion]);
      setCurrentQuestion(nextQuetion);
    }
  };

  const handleFormDataNextSection = () => {
    const currentIndex = checked.indexOf(categories[ccatIndx + 1]);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(categories[ccatIndx + 1]);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    setCurrentCategory(categories[ccatIndx + 1]);
  };

  const handlePrevious = () => {
    const previousQuetion = currentQuestion - 1;
    if (previousQuetion >= 0) {
      setQuestion(questions1stLevel[previousQuetion]);
      setCurrentQuestion(previousQuetion);
    }
  };

  const createFormData = (finalFormData) => {
    let formFieldData = [];
    const firstLevelQuestions = getFirstLevelQuestions(questionsAll);
    let firstLevelUnattendedquestion = JSON.parse(JSON.stringify(firstLevelQuestions));
    if (finalFormData.length > 0) {
      finalFormData.forEach((elemnt, key) => {
        if (elemnt.hasOwnProperty('fileTypeAnswer') && typeof elemnt.fileTypeAnswer !== 'undefined' && elemnt.fileTypeAnswer.length > 0) {
          formFieldData = [...formFieldData, { id: key, value: elemnt.fileTypeAnswer[0] }];
          firstLevelUnattendedquestion = firstLevelUnattendedquestion.filter((item) => item.id !== key);
        } else if (elemnt.hasOwnProperty('formAnswers') && typeof elemnt.formAnswers !== 'undefined' && elemnt.formAnswers !== '') {
          if (typeof elemnt.formAnswers === 'object') {
            if (Array.isArray(elemnt.formAnswers) && elemnt.formAnswers.length > 0) {
              const vals = elemnt.formAnswers.filter((item) => item.value !== '');
              if (vals.length > 0) {
                formFieldData = [...formFieldData, { id: key, value: JSON.stringify(elemnt) }];
                firstLevelUnattendedquestion = firstLevelUnattendedquestion.filter((item) => item.id !== key);
              }
            } else if (elemnt.formAnswers.hasOwnProperty('id')) {
              formFieldData = [...formFieldData, { id: key, value: JSON.stringify(elemnt) }];
              firstLevelUnattendedquestion = firstLevelUnattendedquestion.filter((item) => item.id !== key);
            }
            console.log(
              prefilledAnswers.filter((item, itemkey) => {
                console.log(`${itemkey} !== ${key}`);
              })
            );
          } else if (typeof elemnt.formAnswers === 'string' || typeof elemnt.formAnswers === 'number') {
            formFieldData = [...formFieldData, { id: key, value: JSON.stringify(elemnt) }];
            firstLevelUnattendedquestion = firstLevelUnattendedquestion.filter((item) => item.id !== key);
          }
        }
      });

      prefilledAnswers.forEach((elemnt, key) => {
        if (!finalFormData.some((item, index) => index === key && item.formAnswers)) {
          if (elemnt.hasOwnProperty('type')) {
            formFieldData = [...formFieldData, { id: key, value: JSON.stringify(elemnt.formAnswers) }];
            firstLevelUnattendedquestion = firstLevelUnattendedquestion.filter((item) => item.id !== key);
          } else if (elemnt.hasOwnProperty('formAnswers') && typeof elemnt.formAnswers !== 'undefined' && elemnt.formAnswers !== '') {
            if (typeof elemnt.formAnswers === 'object') {
              if (Array.isArray(elemnt.formAnswers) && elemnt.formAnswers.length > 0) {
                const vals = elemnt.formAnswers.filter((item) => item.value !== '');
                if (vals.length > 0) {
                  formFieldData = [...formFieldData, { id: key, value: JSON.stringify(elemnt) }];
                  firstLevelUnattendedquestion = firstLevelUnattendedquestion.filter((item) => item.id !== key);
                }
              } else if (elemnt.formAnswers.hasOwnProperty('id')) {
                formFieldData = [...formFieldData, { id: key, value: JSON.stringify(elemnt) }];
                firstLevelUnattendedquestion = firstLevelUnattendedquestion.filter((item) => item.id !== key);
              }
            } else if (typeof elemnt.formAnswers === 'string' || typeof elemnt.formAnswers === 'number') {
              formFieldData = [...formFieldData, { id: key, value: JSON.stringify(elemnt) }];
              firstLevelUnattendedquestion = firstLevelUnattendedquestion.filter((item) => item.id !== key);
            }
          }
        }
      });
      setUnattendedQuestions(firstLevelUnattendedquestion);
    }
    console.log(formFieldData.length);
    setFormFieldValues(formFieldData);
  };

  useEffect(() => {
    if (formFieldValues.length > 0) {
      let formData = new FormData();
      formFieldValues.forEach((item) => {
        formData.append(`${item.id}`, item.value);
      });
      switch (buttonType) {
        case 'submit':
          formData.append(`button`, 'submit');
          if (unattendedQuestions.length === 0) {
            submitForm({ params: props.match.params, formData: formData });
          }
          break;
        case 'Save & Exit':
          formData.append(`button`, 'save & exit');
          submitForm({ params: props.match.params, formData: formData });
          break;
      }
    }
  }, [buttonType]);

  const copy = ({ ...props }) => {
    const el = document.createElement('input');
    const url = window.location.href;
    const baseUrl = split(url, '#');
    el.value = `${baseUrl[0]}#catid=${props.target.value}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(props.target.value);
  };

  const goBack = () => {
    setCurrentQuestion(0);
    setButtonType('');
  };

  const submitAsIs = () => {
    setSubmitAsItIs(true);
    if (formFieldValues.length > 0) {
      let formData = new FormData();
      formFieldValues.forEach((item) => {
        formData.append(`${item.id}`, item.value);
      });
      formData.append(`button`, 'submit');
      submitForm({ params: props.match.params, formData: formData });
    }
  };

  const theme = useTheme();
  const matchessm = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  if (isLoading) {
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (error) {
    return (
      <Card style={{ alignItems: 'center' }}>
        <CardContent style={{ height: matchessm ? 480 : 540, overflow: 'auto', paddingLeft: 50, paddingRight: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Paper elevation={0} style={{ lineHeight: '30px' }}>
            <Typography variant="h6" style={{ textAlign: 'center' }}>
              {error || props?.submitError || props?.uasfqError}
            </Typography>
          </Paper>
        </CardContent>
      </Card>
    );
  }

  if (buttonType === 'Save & Exit' && submitAsItIs) {
    return (
      <Card style={{ alignItems: 'center' }}>
        <CardContent style={{ height: matchessm ? 480 : 540, overflow: 'auto', paddingLeft: 50, paddingRight: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Paper elevation={0} style={{ lineHeight: '30px' }}>
            <Typography variant="h6" style={{ textAlign: 'center' }}>
              {dictionary.surveyPopup.saveandexitmsg}
            </Typography>
          </Paper>
        </CardContent>
      </Card>
    );
  }

  if (buttonType === 'submit') {
    if (unattendedQuestions.length > 0 && !submitAsItIs) {
      return (
        <Card style={{ alignItems: 'center' }}>
          <CardContent style={{ height: matchessm ? 480 : 540, overflow: 'auto' }}>
            <Grid container spacing={3} style={{ padding: '10px 20px' }}>
              <Typography variant="h6">
                {dictionary.surveyPopup.following} {unattendedQuestions.length} {dictionary.surveyPopup['question(s) not answered yet']}
              </Typography>
            </Grid>
            <List dense={true}>
              {unattendedQuestions.map((item, index) => (
                <ListItem key={item.id}>
                  <ListItemText primary={`${index + 1}) ${item.surveyFormQuestion.label}`} />
                </ListItem>
              ))}
            </List>
          </CardContent>
          <CardActions style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #ddd' }}>
            <Button variant="contained" color="primary" size="small" onClick={goBack}>
              {dictionary.surveyPopup.yesGoBack}
            </Button>
            <Button variant="contained" color="primary" size="small" onClick={submitAsIs}>
              {dictionary.surveyPopup.submitAsIs}
            </Button>
          </CardActions>
        </Card>
      );
    } else {
      return <div className="flex items-center justify-center h-screen p-4 text-sm font-medium tracking-tight md:text-base">{dictionary.surveyPopup.complitionmsg}</div>;
    }
  }

  if (questionsAll.length > 0 && questions1stLevel.length > 0 && question.hasOwnProperty('surveyFormQuestion')) {
    const indexOfLastTodo = (currentQuestion + 1) * 3;
    const indexOfFirstTodo = indexOfLastTodo - 3;
    const currentTodos = questions1stLevel.slice(indexOfFirstTodo, indexOfLastTodo);
    const renderQuestions = currentTodos.map((question, index) => {
      return <SurveyCampaign1stLevelForm key={index} question={question} currentQuestion={index + 3 * currentQuestion} methods={methods} catId={currentCategory} prefilledAnswers={prefilledAnswers} />;
    });

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(questions1stLevel.length / 3); i++) {
      pageNumbers.push(i);
    }

    return (
      <div className={cx('root-wrapper')}>
        <div className={cx('upper-wrapper')}>
          <div className="flex justify-between w-full">
            <h3 className={cx('page-title')}>{pinpointCampaign?.campaignName}</h3>

            {((currentQuestion >= 1 && currentQuestion + 1 < questions1stLevel.length) ||
              (currentQuestion + 1 === pageNumbers.length && ccatIndx + 1 == categories.length) ||
              (ccatIndx > 0 && ccatIndx + 1 < categories.length && questions1stLevel.length >= 1)) && (
              <button
                className={cx('button', 'transparent', 'cap', 'font-medium')}
                onClick={handleSubmit((data) => {
                  createFormData(data.questions);
                  setButtonType('Save & Exit');
                  setSubmitAsItIs(true);
                })}>
                <span>{dictionary.surveyPopup.savenexit}</span>
              </button>
            )}
          </div>

          <div className={cx('progress-wrapper')}>
            <div className={cx('progressbar')} style={{ width: `${(currentQuestion / questions1stLevel.length) * 100}%`, height: '8px' }} />
          </div>
          <div className={cx('pagination', 'prev')}>
            <button className={cx('button', 'col', 'transparent', 'space-1', { hidden: pageNumbers.length > 1 })} onClick={handlePrevious} disabled={currentQuestion === 0}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className={cx('icon')}>
                <path fillRule="evenodd" d="M18 10a.75.75 0 01-.75.75H4.66l2.1 1.95a.75.75 0 11-1.02 1.1l-3.5-3.25a.75.75 0 010-1.1l3.5-3.25a.75.75 0 111.02 1.1l-2.1 1.95h12.59A.75.75 0 0118 10z" clipRule="evenodd" />
              </svg>
              <span>{dictionary.surveyPopup.previous}</span>
            </button>

            <h4 className={cx('progress-percent')}>{`${Math.round((currentQuestion / questions1stLevel.length) * 100)}%`}</h4>
          </div>
        </div>

        <div className={cx('wrapper')}>
          <aside className={cx('sidebar')}>
            {categories.map((value, index) => {
              return (
                <div key={value.id}>
                  <div className={cx('items', { grayed: index === ccatIndx || value.questions.length === 0 })} key={index} onClick={handleToggle(value)}>
                    <CheckCircleIcon className={cx('icon', { active: value.questions.length === 0 })} />
                    <span className={cx('title')}>{value.title}</span>
                    <div className={cx('modal')}>
                      <SurveyModal title={value.title} url={copy} obj={value} />
                    </div>
                  </div>
                </div>
              );
            })}
          </aside>
          <div className={cx('questions-root-wrapper')}>
            <div>
              <div className={cx('questions-wrapper', { 'form-wrapper': formSection })}>
                <div className={cx('render-questions')}>{renderQuestions}</div>
              </div>
              <div className={cx('question-helper-buttons')}>
                {currentQuestion + 1 === pageNumbers.length && ccatIndx + 1 == categories.length && (
                  <button
                    className={cx('button')}
                    onClick={handleSubmit((data) => {
                      createFormData(data.questions);
                      setButtonType('submit');
                      setSubmitAsItIs(false);
                    })}>
                    <span>{dictionary.surveyPopup.submit}</span>
                  </button>
                )}

                {currentQuestion + 1 < pageNumbers.length && (
                  <>
                    <button className={cx('button', 'col', 'space-1')} onClick={handleSubmit(handleNext)}>
                      <span>{dictionary.surveyPopup.next}</span>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className={cx('icon', 'rotate-180')}>
                        <path fillRule="evenodd" d="M18 10a.75.75 0 01-.75.75H4.66l2.1 1.95a.75.75 0 11-1.02 1.1l-3.5-3.25a.75.75 0 010-1.1l3.5-3.25a.75.75 0 111.02 1.1l-2.1 1.95h12.59A.75.75 0 0118 10z" clipRule="evenodd" />
                      </svg>
                    </button>
                  </>
                )}

                {currentQuestion + 1 === pageNumbers.length && ccatIndx + 1 < categories.length && (
                  <button className={cx('button')} onClick={handleSubmit(handleFormDataNextSection)}>
                    <span>Next Section</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default SurveyCampaignForm;
