import React, { useContext } from 'react';
import { TextField, FormControl, FormGroup } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { LanguageContext } from '../../contexts/LanguageContext';

const OtherTextInput = ({ id, name, control, required, level, typeRestriction, questionData, currentQuestionIndex, answeredData }) => {
  const { dictionary } = useContext(LanguageContext);
  return (
    <FormControl component="fieldset" fullWidth>
      <FormGroup>
        <Controller
          {...{
            name: name,
            control: control,
            rules: {
              required: {
                value: required,
                message: dictionary.surveyPopup.requiredQuestion
              }
            },
            defaultValue: answeredData?.otherAnswerText,
            render: ({ field: { onChange, value }, fieldState: { error }, formState }) => (
              <TextField helperText={error ? error.message : null} size="small" error={!!error} onChange={onChange} value={value} fullWidth multiline placeholder={questionData.otherAnswerText} variant="outlined" />
            )
          }}
        />
      </FormGroup>
    </FormControl>
  );
};

export default OtherTextInput;
