import React, { Fragment, useContext, useState } from 'react';
import { FormHelperText } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { LanguageContext } from '../../../contexts/LanguageContext';

import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import bindClassNames from 'classnames/bind';
import styles from './index.module.scss';
import useComponentVisible from '../../../hooks/withClickOutside';
import { useFetchEthnicitiesQuery } from '../../../store';
const cx = bindClassNames.bind(styles);

const EthnicityDropdown = ({ name, label, control, required }) => {
  const { dictionary } = useContext(LanguageContext);
  const { data, error, isLoading } = useFetchEthnicitiesQuery();
  const [selectedEthnicity, setSelectedEthnicity] = useState(null);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  let ethnicityItemsHtml;
  if (isLoading) {
    ethnicityItemsHtml = <div className={cx('not-available')}>...dropdown loading</div>;
  } else if (error) {
    ethnicityItemsHtml = <div className={cx('not-available')}>there is error in loading.</div>;
  } else {
    const ethnicities = data['hydra:member'].filter((race) => race.name !== '');
    let filteredEthnicities = ethnicities.filter((ethnicity) => ethnicity.name.toLowerCase().replace(/\s+/g, ''));
    ethnicityItemsHtml = filteredEthnicities.map((ethnicity, index) => (
      <Listbox.Option key={`ethnicity_${index}`} value={ethnicity.id} className={cx('dob-wrapper')} onClick={() => setSelectedEthnicity(ethnicity.name)}>
        <span className={cx('dob')}>{ethnicity.name}</span>
      </Listbox.Option>
    ));
  }
  return (
    <div className={cx('root-wrapper')} ref={ref}>
      <label htmlFor={name} className={cx('label')}>
        {label}
      </label>
      <label htmlFor={name} className={cx('label', 'screen-reader')}>
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        defaultValue={''}
        render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
          <Fragment>
            <Listbox
              value={value}
              onChange={(e) => {
                onChange(e);
              }}>
              <div className={cx('wrapper')}>
                <Listbox.Button className={cx('button')} onClick={() => setIsComponentVisible(!isComponentVisible)}>
                  <span className={cx('screen-reader')}>{selectedEthnicity ? selectedEthnicity : 'Select Ethnicity'}</span>
                  <span className={cx('dob')}>{selectedEthnicity ? selectedEthnicity : 'Select Ethnicity'}</span>
                  <span className={cx('icon-wrapper')}>
                    <ChevronDownIcon className={cx(['icon', { 'icon-rotate': isComponentVisible }])} aria-hidden="true" />
                  </span>
                </Listbox.Button>
                <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                  <Listbox.Options className={cx('options')} onClick={() => setIsComponentVisible(false)}>
                    {ethnicityItemsHtml}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
            <FormHelperText error={!!error}>{error ? error.message : null}</FormHelperText>
          </Fragment>
        )}
        rules={{
          required: {
            value: required,
            message: dictionary.landingPage.form.requiredField
          }
        }}
      />
    </div>
  );
};

export default EthnicityDropdown;
