import React, { useState, useContext, Fragment, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { LanguageContext } from '../../../contexts/LanguageContext';
import useLogout from '../../../contexts/useLogout';
import { UserContext } from '../../../contexts/UserContext';
import KanarysLogo from '../../../assets/images/ki4uQJtG-image.png';

import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';

import bindClassNames from 'classnames/bind';
import styles from './index.module.scss';
const cx = bindClassNames.bind(styles);

const navigation = [{ name: 'About', href: '/about', current: false }];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Header() {
  const { dictionary } = useContext(LanguageContext);
  const { user, setUser } = useContext(UserContext);
  const { logoutUser } = useLogout();
  const [open, setOpen] = useState(false);
  const [sticky, setSticky] = useState('');

  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  }, []);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 50 ? 'bg-krs-gray-200 shadow' : '';
    setSticky(stickyClass);
  };

  const handleLogout = () => {
    setUser(null);
    logoutUser();
  };

  return (
    <section className={cx('header', sticky)}>
      <Disclosure as="nav">
        {({ open }) => (
          <Fragment>
            <div className={cx('wrapper')}>
              <div className={cx('content')}>
                <div className={cx('mobile_menu')}>
                  {/* Mobile menu button*/}
                  <Disclosure.Button className={cx('hamburger')}>
                    <span className="sr-only">Open main menu</span>
                    {open ? <XMarkIcon className={cx('open')} aria-hidden="true" /> : <Bars3Icon className={cx('close')} aria-hidden="true" />}
                  </Disclosure.Button>
                </div>

                <div className={cx('desktop_menu')}>
                  <div className={cx('logo')}>
                    <RouterLink to="/">
                      <img className={cx('image_mobile')} src={KanarysLogo} alt="Kanarys Logo" />
                    </RouterLink>

                    <RouterLink to="/">
                      <img className={cx('image_desktop')} src={KanarysLogo} alt="Kanarys Logo" />
                    </RouterLink>
                  </div>

                  <div className={cx('desktop_nav')}>
                    <div className={cx('items')}>
                      {navigation.map((item) => (
                        <RouterLink key={item.name} to={item.href} className={classNames(item.current ? 'bg-gray-900 text-gray-100' : 'text-gray-700', 'px-3 py-2 rounded-md text-base font-medium')} aria-current={item.current ? 'page' : undefined}>
                          {item.name}
                        </RouterLink>
                      ))}
                    </div>
                  </div>
                </div>
                <div className={cx('desktop_main_bar')}>
                  {user ? (
                    <Menu as="div" className={cx('desktop_main_menu')}>
                      <div>
                        <Menu.Button className={cx('menu_button')}>
                          <span className="sr-only">Open user menu</span>
                          <img className={cx('main_menu_image')} src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95">
                        <Menu.Items className={cx('main_menu_items')}>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={cx('main_menu_item', {
                                  main_menu_item_active: active
                                })}>
                                Your Profile
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={cx('main_menu_item', {
                                  main_menu_item_active: active
                                })}>
                                Settings
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={cx('main_menu_item', {
                                  main_menu_item_active: active
                                })}
                                onClick={handleLogout}>
                                Sign out
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  ) : (
                    <div className={cx('desktop_main_menu')}>
                      <RouterLink to="/login">
                        <div className={cx('main_button')}>
                          <span className="sr-only">{dictionary.topMenu.login}</span>
                          <button className={cx('login')}>{dictionary.topMenu.login}</button>
                        </div>
                      </RouterLink>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <Disclosure.Panel className={cx('mobile_menu_panel')}>
              <div className={cx('mobile_menu_content')}>
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={cx('mobile_menu_item', {
                      mobile_menu_item_active: item.current
                    })}
                    aria-current={item.current ? 'page' : undefined}>
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </Fragment>
        )}
      </Disclosure>
    </section>
  );
}
