import React from 'react';
import { Route, Switch } from 'react-router-dom';
import About from '../components/about/About';
import Login from '../components/authentication/login';
import Footer from '../components/common/Footer';
import Header from '../components/common/Header';
//import PrivateRoute from '../components/common/PrivateRoute';
import Home from '../components/Home';
import PageNotFound from '../components/PageNotFound';
import PrivacyPolicy from '../components/PrivacyPolicy';
import Dashboard from '../components/Dashboard';
import TermsOfUse from '../components/Terms/TermsOfUse';
import TermsOfUseOrg from '../pages/terms-of-use-organizations';
import CompanyPartnerAgreement from '../pages/company-partner-agreement';
import FAQ from '../pages/faq';
import SurveyCampaign from '../components/surveycampaign/SurveyCampaign';
import SurveyCampaignDialog from '../components/surveycampaign/SurveyCampaignDialog';
import SurveyCampaignForm from '../components/surveycampaign/SurveyCampaignForm';
import SurveyCampaignLanding from '../components/surveycampaign/SurveyCampaignLanding';
import { LanguageProvider } from '../contexts/LanguageContext';

const routes = (
  <LanguageProvider>
    <Header />
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/home" component={Home} />
      <Route path="/about" component={About} />
      {/* <PrivateRoute path="/survey-campaign/campaign/:userId/:campaignId" component={SurveyCampaign} />
          <PrivateRoute path="/my-account/:userId/:campaignId" component={SurveyCampaignDialog} /> */}
      <Route path="/survey-campaign/campaign/:userId/:companyId/:campaignId" component={SurveyCampaign} />
      <Route path="/survey-campaign/popup/:userId/:companyId/:campaignId" component={SurveyCampaignDialog} />
      <Route path="/survey-campaign/form/:userId/:companyId/:campaignId" component={SurveyCampaignForm} />
      <Route path="/survey-campaign/campaign/:campaignId" component={SurveyCampaignLanding} />
      <Route path="/login" component={Login} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/terms-of-use" component={TermsOfUse} />
      <Route path="/terms-of-use-organizations" component={TermsOfUseOrg} />
      <Route path="/company-partner-agreement" component={CompanyPartnerAgreement} />
      <Route path="/faq" component={FAQ} />
      <Route path="/dashboard" component={Dashboard} />
      <Route component={PageNotFound} />
    </Switch>
    <Footer />
  </LanguageProvider>
);

export default routes;
