import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const MIME_TYPE = 'application/ld+json';
const ENTRYPOINT = process.env.REACT_APP_API_SERVER || '';
let lang = localStorage.getItem('rcml-lang');
let token = localStorage.getItem('token');

const usersApi = createApi({
  reducerPath: 'users',
  baseQuery: fetchBaseQuery({
    baseUrl: ENTRYPOINT,
    prepareHeaders: (headers, { state }) => {
      headers.set('X-LOCALE', lang || 'en');
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      if (null === headers.get('Accept')) headers.set('Accept', MIME_TYPE);
    }
  }),
  endpoints(builder) {
    return {
      searchUser: builder.query({
        query: (inputValue) => decodeURIComponent(`users?search=${inputValue}`)
      }),
      authLogin: builder.mutation({
        query: ({ params, ...rest }) => {
          return {
            url: decodeURIComponent(`survey-campaign/${params.campaignId}/${params.userId}/campaign`),
            method: 'POST',
            body: rest
          };
        }
      })
    };
  }
});
export const { useSearchUserQuery, useAuthLoginMutation } = usersApi;
export { usersApi };
