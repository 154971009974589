import { createSlice } from '@reduxjs/toolkit';
import { loadAssessmentQuestions } from '../thunks/loadAssessmentQuestions';

const surveyCampaignSlice = createSlice({
  name: 'surveyCampaign',
  initialState: {
    isLoading: false,
    surveyCompanySurveyFormQuestion: [],
    error: null
  },
  extraReducers(builder) {
    builder.addCase(loadAssessmentQuestions.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(loadAssessmentQuestions.fulfilled, (state, action) => {
      state.isLoading = false;
      console.log(action.payload);
      state.surveyCompanySurveyFormQuestion = action.payload;
    });
    builder.addCase(loadAssessmentQuestions.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  }
});

export const surveyCampaignReducer = surveyCampaignSlice.reducer;
