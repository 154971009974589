import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import { HtmlTooltip } from '../ToolTip';
import { useLocation } from 'react-router-dom';

import bindClassNames from 'classnames/bind';
import styles from './index.module.scss';
const cx = bindClassNames.bind(styles);

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  questionIndex: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(1.2)'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(3.0)'
  },
  doubleBullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(2.4)'
  },
  thirdBullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(1.8)'
  },
  mainGrid: {
    marginTop: theme.spacing(3)
  },
  title: {
    fontSize: 12
  },
  pos: {
    marginBottom: 12
  }
}));

const QuestionText = ({ surveyFormQuestion, level, infoText, currentQuesIndex }) => {
  const [formSection, setFormSection] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.match(/\/form\//)) {
      setFormSection(true);
    }
  }, [location]);

  switch (level) {
    case 1:
      return (
        <Fragment>
          {surveyFormQuestion?.type === 'choice-unique-expanded' ? <span className={cx('question-heading', { left: formSection })}>Single choice</span> : <Fragment />}
          {surveyFormQuestion?.type === 'choice-true-false' ? <span className={cx('question-heading', { left: formSection })}>True False</span> : <Fragment />}
          {surveyFormQuestion.type === 'choice-multiple-expanded' || surveyFormQuestion.type === 'choice-multiple-collapsed' ? <span className={cx('question-heading', { left: formSection })}>Multiple Choice</span> : <Fragment />}

          <div className={cx('content', { left: formSection })}>
            <h6 className={cx('label', { left: formSection })}>{surveyFormQuestion?.label}</h6>
            {/* <span className={classes.bullet}>•</span> {surveyFormQuestion?.label} */}
            {infoText && (
              <div
                className={cx('mt-0.5', {
                  'mb-1 self-center': formSection
                })}>
                <HtmlTooltip title={<div dangerouslySetInnerHTML={{ __html: infoText }} />}>
                  <HelpIcon
                    color="primary"
                    style={{
                      width: 20,
                      height: 20
                    }}
                  />
                </HtmlTooltip>
              </div>
            )}
          </div>
        </Fragment>
      );
    case 2:
      return (
        <Fragment>
          {surveyFormQuestion?.type === 'choice-unique-expanded' ? <span className={cx('question-heading', { left: formSection })}>Single choice</span> : <Fragment />}
          {surveyFormQuestion?.type === 'choice-true-false' ? <span className={cx('question-heading', { left: formSection })}>True False</span> : <Fragment />}
          {surveyFormQuestion.type === 'choice-multiple-expanded' || surveyFormQuestion.type === 'choice-multiple-collapsed' ? <span className={cx('question-heading', { left: formSection })}>Multiple Choice</span> : <Fragment />}

          <div className={cx('content', { left: formSection })}>
            <h6 className={cx('label', { left: formSection })}>
              <span>{currentQuesIndex + 1}) </span>
              {surveyFormQuestion?.label}
            </h6>
            {/* <span className={classes.doubleBullet}>•</span> {surveyFormQuestion?.label} */}
            {infoText && (
              <div
                className={cx('mt-0.5', {
                  'mb-1 self-center': formSection
                })}>
                <HtmlTooltip title={<div dangerouslySetInnerHTML={{ __html: infoText }} />}>
                  <HelpIcon
                    color="primary"
                    style={{
                      width: 20,
                      height: 20
                    }}
                  />
                </HtmlTooltip>
              </div>
            )}
          </div>
        </Fragment>
      );
    case 3:
      return (
        <Fragment>
          {surveyFormQuestion?.type === 'choice-unique-expanded' ? <span className={cx('question-heading', { left: formSection })}>Single choice</span> : <Fragment />}
          {surveyFormQuestion?.type === 'choice-true-false' ? <span className={cx('question-heading', { left: formSection })}>True False</span> : <Fragment />}
          {surveyFormQuestion.type === 'choice-multiple-expanded' || surveyFormQuestion.type === 'choice-multiple-collapsed' ? <span className={cx('question-heading', { left: formSection })}>Multiple Choice</span> : <Fragment />}

          <div className={cx('content', { left: formSection })}>
            <h6 className={cx('label', { left: formSection })}>{surveyFormQuestion?.label}</h6>
            {/* <span className={classes.thirdBullet}>•</span> {surveyFormQuestion?.label} */}
            {infoText && (
              <div
                className={cx('mt-0.5', {
                  'mb-1 self-center': formSection
                })}>
                <HtmlTooltip title={<div dangerouslySetInnerHTML={{ __html: infoText }} />}>
                  <HelpIcon
                    color="primary"
                    style={{
                      width: 20,
                      height: 20
                    }}
                  />
                </HtmlTooltip>
              </div>
            )}
          </div>
        </Fragment>
      );
    default:
      return (
        <Fragment>
          {surveyFormQuestion?.type === 'choice-unique-expanded' ? <span className={cx('question-heading', { left: formSection })}>Single choice</span> : <Fragment />}
          {surveyFormQuestion?.type === 'choice-true-false' ? <span className={cx('question-heading', { left: formSection })}>True False</span> : <Fragment />}
          {surveyFormQuestion.type === 'choice-multiple-expanded' || surveyFormQuestion.type === 'choice-multiple-collapsed' ? <span className={cx('question-heading', { left: formSection })}>Multiple Choice</span> : <Fragment />}

          <div className={cx('content', { left: formSection })}>
            <h6 className={cx('label', { left: formSection })}>
              <span>{currentQuesIndex + 1}) </span>
              {surveyFormQuestion?.label}
            </h6>
            {infoText && (
              <div
                className={cx('mt-0.5', {
                  'mb-1 self-center': formSection
                })}>
                <HtmlTooltip title={<div dangerouslySetInnerHTML={{ __html: infoText }} />}>
                  <HelpIcon
                    color="primary"
                    style={{
                      width: 20,
                      height: 20
                    }}
                  />
                </HtmlTooltip>
              </div>
            )}
          </div>
        </Fragment>
      );
  }
};

export default QuestionText;
