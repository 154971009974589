import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { companiesApi } from './apis/companiesApi';
import { departmentsApi } from './apis/departmentsApi';
import { ethnicityApi } from './apis/ethnicitiesApi';
import { gendersApi } from './apis/gendersApi';
import { pagesApi } from './apis/pagesApi';
import { positionsApi } from './apis/positionsApi';
import { racesApi } from './apis/racesApi';
import { surveyCampaignApi } from './apis/surveyCampaignApi';
import { surveyContributionsApi } from './apis/surveyContributionsApi';
import { usersApi } from './apis/usersApi';
import { surveyCampaignReducer } from './slices/surveyCampaignSlice';

export const store = configureStore({
  reducer: {
    assessmentQuestions: surveyCampaignReducer,
    [surveyCampaignApi.reducerPath]: surveyCampaignApi.reducer,
    [positionsApi.reducerPath]: positionsApi.reducer,
    [departmentsApi.reducerPath]: departmentsApi.reducer,
    [racesApi.reducerPath]: racesApi.reducer,
    [gendersApi.reducerPath]: gendersApi.reducer,
    [ethnicityApi.reducerPath]: ethnicityApi.reducer,
    [companiesApi.reducerPath]: companiesApi.reducer,
    [surveyContributionsApi.reducerPath]: surveyContributionsApi.reducer,
    [pagesApi.reducerPath]: pagesApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(surveyCampaignApi.middleware)
      .concat(companiesApi.middleware)
      .concat(positionsApi.middleware)
      .concat(departmentsApi.middleware)
      .concat(racesApi.middleware)
      .concat(gendersApi.middleware)
      .concat(ethnicityApi.middleware)
      .concat(surveyContributionsApi.middleware)
      .concat(pagesApi.middleware)
      .concat(usersApi.middleware);
  }
});

setupListeners(store.dispatch);

export * from './thunks/loadAssessmentQuestions';
export { useAssessmentLandingPageQuery, useAssessmentLoginMutation, useFetchFormSurveyQuestionsQuery, useFetchPopupSurveyQuestionsQuery, useSubmitFormSurveyQuestionsMutation, useSubmitPopupSurveyQuestionsMutation } from './apis/surveyCampaignApi';
export { useFetchPositionsQuery } from './apis/positionsApi';
export { useFetchDepartmentsQuery } from './apis/departmentsApi';
export { useFetchRacesQuery } from './apis/racesApi';
export { useFetchGendersQuery } from './apis/gendersApi';
export { useFetchEthnicitiesQuery } from './apis/ethnicitiesApi';
export { useFetchCompaniesQuery } from './apis/companiesApi';
export { useFetchSurveyContributionsQuery } from './apis/surveyContributionsApi';
export { useCmsPageQuery } from './apis/pagesApi';
export { useSearchUserQuery, useAuthLoginMutation } from './apis/usersApi';
