import React, { useEffect } from 'react';
import { Fragment, useContext, useState } from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { styled } from '@material-ui/core/styles';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { UserContext } from '../../../contexts/UserContext';
import { XMarkIcon, UserPlusIcon, LinkIcon } from '@heroicons/react/20/solid';
import { Dialog, Transition } from '@headlessui/react';
import useAuth from '../../../contexts/useAuth';
import _ from 'lodash';

import bindClassNames from 'classnames/bind';
import styles from './index.module.scss';
import SurveyCampaignContext from '../../../contexts/SurveyCampaignContext';
import { useSearchUserQuery } from '../../../store/apis/usersApi';
const cx = bindClassNames.bind(styles);

const AutocompleteStyled = styled(Autocomplete)({
  '& .MuiAutocomplete-root ': {
    width: '65% !important'
  }
});

const SurveyModal = ({ title = 'Survey Question', url = '', limit = 4, obj = {}, ...props }) => {
  const { loginUser } = useAuth();
  const { user } = useContext(UserContext);
  const { assessmentUser } = useContext(SurveyCampaignContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [allEmails, setAllEmails] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { data: retrieved, error, isLoading } = useSearchUserQuery(inputValue);

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (retrieved && retrieved['hydra:member'].length >= 0) {
      setAllEmails(retrieved['hydra:member']);
    }
  }, [retrieved, inputValue]);

  const handleChange = (event, inputs, reason) => {
    if ('select-option' === reason) {
      setSelectedUsers(inputs);
    } else {
      setSelectedUsers([]);
    }
  };
  return (
    <Fragment>
      <div className={cx('survey-wrapper')}>
        <button type="button" className={cx('modal-icon')}>
          <UserPlusIcon className={cx(['modal-icon', 'icon'])} onClick={() => setIsOpen(true)} aria-hidden="true" />
        </button>
      </div>
      {/* {isOpen ? ( */}
      {/*   <Dialog open={isOpen} onClose={() => setIsOpen(false)} aria-labelledby="form-dialog-title"> */}
      {/*     <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
      {/*     <DialogContent> */}
      {/*       <DialogContentText>To subscribe to this website, please enter your email address here. We will send updates occasionally.</DialogContentText> */}
      {/*       <AutocompleteStyled */}
      {/*         className="w-[65%]" */}
      {/*         multiple */}
      {/*         id="tags-filled" */}
      {/*         options={allEmails.map((option) => option.email)} */}
      {/*         freeSolo */}
      {/*         renderTags={(value, getTagProps) => value.map((option, index) => <Chip key={index} variant="outlined" label={option} {...getTagProps({ index })} />)} */}
      {/*         renderInput={(params) => <TextField {...params} variant="filled" placeholder="Emails, Comma Seperated" onChange={(event) => setInputValue(event.target.value)} />} */}
      {/*         onChange={handleChange} */}
      {/*       /> */}
      {/*     </DialogContent> */}
      {/*     <DialogActions> */}
      {/*       <Button onClick={() => setIsOpen(false)} color="primary"> */}
      {/*         Cancel */}
      {/*       </Button> */}
      {/*       <Button onClick={() => console.log(selectedUsers)} color="primary"> */}
      {/*         Subscribe */}
      {/*       </Button> */}
      {/*     </DialogActions> */}
      {/*   </Dialog> */}
      {/* ) : null} */}

      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={1000}
        onClose={() => setIsSnackbarOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}>
        <SnackbarContent className={cx('popup')} message="Copied to clipboard" />
      </Snackbar>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className={cx('dialog-index')} onClose={closeModal}>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className={cx('background-dim')} />
          </Transition.Child>

          <div className={cx('content-wrapper')}>
            <div className={cx('content')}>
              <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                <Dialog.Panel className={cx('panel')}>
                  <div className={cx('panel-content')}>
                    <Dialog.Title as="h3" className={cx('title')}>
                      Share “{title}”
                    </Dialog.Title>
                    <span onClick={closeModal}>
                      <XMarkIcon className={cx('close-icon')} />
                    </span>
                  </div>

                  <form className={cx('form')}>
                    <AutocompleteStyled
                      className="w-[65%]"
                      multiple
                      id="tags-filled"
                      options={allEmails.map((option) => option.email)}
                      freeSolo
                      renderTags={(value, getTagProps) => value.map((option, index) => <Chip key={index} variant="outlined" label={option} {...getTagProps({ index })} />)}
                      renderInput={(params) => <TextField {...params} variant="filled" placeholder="Emails, Comma Seperated" onChange={(event) => setInputValue(event.target.value)} />}
                      onChange={handleChange}
                    />

                    <button type="submit" className={cx('invite', 'invite-primary')}>
                      Send Invite
                    </button>
                  </form>

                  {/* <span className={cx('error')}>That is the max people you can invite.</span> */}

                  <div className={cx('copy-link-wrapper')} onClick={() => setIsSnackbarOpen(true)}>
                    <LinkIcon className={cx('link-icon')} onClick={url} value={obj?.id} />
                    <button type="button" className={cx('copy-link')} value={obj?.id} onClick={url}>
                      Copy Link
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </Fragment>
  );
};

export default SurveyModal;
