import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const MIME_TYPE = 'application/ld+json';
const ENTRYPOINT = process.env.REACT_APP_API_SERVER || '';
let lang = localStorage.getItem('rcml-lang');
let token = localStorage.getItem('token');

const surveyCampaignApi = createApi({
  reducerPath: 'survey-campaign',
  baseQuery: fetchBaseQuery({
    baseUrl: ENTRYPOINT,
    prepareHeaders: (headers, { state }) => {
      headers.set('X-LOCALE', lang || 'en');
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      if (null === headers.get('Accept')) headers.set('Accept', MIME_TYPE);
    }
  }),
  endpoints(builder) {
    return {
      assessmentLandingPage: builder.query({
        query: (campaignId) => `survey-campaign/${campaignId}/landing`
      }),
      assessmentLogin: builder.mutation({
        query: ({ campaignId, ...rest }) => {
          return {
            url: `survey-campaign/${campaignId}/login`,
            method: 'POST',
            body: rest
          };
        }
      }),
      fetchFormSurveyQuestions: builder.query({
        query: (params) => decodeURIComponent(`survey-campaign/${params.campaignId}/${params.userId}/form`)
      }),
      fetchPopupSurveyQuestions: builder.query({
        query: (params) => decodeURIComponent(`survey-campaign/${params.campaignId}/${params.userId}/campaign`)
      }),
      submitFormSurveyQuestions: builder.mutation({
        query: ({ params, formData }) => {
          return {
            url: decodeURIComponent(`survey-campaign/${params.campaignId}/${params.userId}/form`),
            method: 'POST',
            body: formData
          };
        }
      }),
      submitPopupSurveyQuestions: builder.mutation({
        query: ({ params, formData }) => {
          console.log(params);
          return {
            url: decodeURIComponent(`survey-campaign/${params.campaignId}/${params.userId}/campaign`),
            method: 'POST',
            body: formData
          };
        }
      })
    };
  }
});
export const { useAssessmentLandingPageQuery, useFetchFormSurveyQuestionsQuery, useFetchPopupSurveyQuestionsQuery, useAssessmentLoginMutation, useSubmitFormSurveyQuestionsMutation, useSubmitPopupSurveyQuestionsMutation } = surveyCampaignApi;
export { surveyCampaignApi };
