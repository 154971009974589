import React, { useContext } from 'react';
import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { LanguageContext } from '../../contexts/LanguageContext';
import { styled } from '@material-ui/core/styles';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  icon: {
    borderRadius: 4,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    '$root.Mui-focusVisible &': {
      outline: '1px auto #3A6CC8',
      outlineOffset: 2
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)'
    }
  },
  checkedIcon: {
    backgroundColor: '#3A6CC8',
    // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    color: '#fff',
    position: 'relative',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M7.602.394c.137.134.139.354.004.491a18.317 18.317 0 0 0-3.867 5.87.347.347 0 0 1-.567.114L.396 4.093a.347.347 0 1 1 .492-.491L3.3 6.014A19.022 19.022 0 0 1 7.11.399a.347.347 0 0 1 .492-.005Z' clip-rule='evenodd'/%3E%3C/svg%3E\")",
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      content: '""',
      position: 'absolute',
      top: 3,
      left: 2.5
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3'
    }
  }
});
const FormControlLabelStyled = styled(FormControlLabel)({
  '& .MuiCheckbox-root': {
    alignSelf: 'baseline'
  },
  // class='w-5 h-5'
  checkedIcon: {
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      content: '""'
    }
  }
});

const AgreeCheckBoxInput = ({ name, control, required, ...props }) => {
  const { dictionary } = useContext(LanguageContext);
  const classes = useStyles();

  return (
    <FormControl component="fieldset" fullWidth>
      <Controller
        name={name}
        render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
          <React.Fragment>
            <FormControlLabelStyled
              control={<Checkbox className={classes.root} checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />} icon={<span className={classes.icon} />} onChange={(e) => onChange(e.target.checked)} checked={!!value} />}
              {...props}
            />
            {/* <FormHelperText error={!!error}>{error ? error.message : null}</FormHelperText> */}
            {!!error && <div className="text-xs text-[red] ml-[4.5%] mt-2 tracking-wide font-semibold">{error ? error.message : null}</div>}
          </React.Fragment>
        )}
        control={control}
        rules={{
          required: {
            value: required,
            message: dictionary.landingPage.form.requiredField
          }
        }}
      />
    </FormControl>
  );
};

export default AgreeCheckBoxInput;
