import React, { useContext, useState, Fragment } from 'react';
import { Controller } from 'react-hook-form';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';

import bindClassNames from 'classnames/bind';
import styles from './index.module.scss';
import { useFetchDepartmentsQuery } from '../../../store';
const cx = bindClassNames.bind(styles);

const DepartmentDropdown = ({ name, label, control, required }) => {
  const { data, error, isLoading } = useFetchDepartmentsQuery();
  const { dictionary } = useContext(LanguageContext);
  const [query, setQuery] = useState('');

  let combohtml;

  if (isLoading) {
    combohtml = <div className={cx('not-available')}>...dropdown loading</div>;
  } else if (error) {
    combohtml = <div className={cx('not-available')}>there is error in loading.</div>;
  } else {
    const departments = data['hydra:member'].filter((department) => department.name !== '');
    let filteredDepartments = query === '' ? departments : departments.filter((department) => department.name.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')));
    if (!filteredDepartments || (filteredDepartments.length === 0 && query !== '')) {
      combohtml = <div className={cx('not-available')}>No Available Department.</div>;
    } else {
      combohtml = filteredDepartments.map((department) => (
        <Combobox.Option key={department.id} className={({ active }) => `relative cursor-pointer select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'}`} value={department}>
          {({ selected, active }) => (
            <>
              <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{department.name ? department.name : ''}</span>
              {selected ? (
                <span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'}`}>
                  <CheckIcon className="w-5 h-5" aria-hidden="true" />
                </span>
              ) : (
                ''
              )}
            </>
          )}
        </Combobox.Option>
      ));
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={''}
      value={''}
      render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
        <div className={cx('root-wrapper')}>
          <Combobox
            value={value.name || ''}
            onChange={(value) => {
              onChange(value);
            }}>
            <Combobox.Label className={cx('label')}>{dictionary.landingPage.form.ChooseDepartment}</Combobox.Label>
            <div className={cx('wrapper')}>
              <Combobox.Input
                className={cx('input')}
                placeholder={dictionary.landingPage.form.ChooseDepartment}
                displayValue={value.name}
                autoComplete="off"
                onChange={(event) => {
                  setQuery(event.target.value);
                }}
              />
              <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" afterLeave={() => setQuery('')}>
                <Combobox.Options className={cx('options')}>{combohtml}</Combobox.Options>
              </Transition>
            </div>
          </Combobox>
          {!!error && <p className={cx('error')}>{error ? error.message : null}</p>}
        </div>
      )}
      onChange={([event, data]) => {
        return data;
      }}
      rules={{
        required: {
          value: required,
          message: dictionary.landingPage.form.requiredField
        }
      }}
    />
  );
};

export default DepartmentDropdown;
