import React, { useContext, useState } from 'react';
import { Typography, FormControl, FormGroup, Paper, Button, FormHelperText, Box, Link } from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import QuestionText from './questionText';
import { Controller } from 'react-hook-form';
import { LanguageContext } from '../../contexts/LanguageContext';

const useStyles = makeStyles((theme) => ({
  flexPaper: {
    flex: 1,
    margin: 16,
    minWidth: 350
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}));

const imageArr = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];

export const FileUploadInput = ({ id, name, control, infoText, level, questionData, currentQuestionIndex, required, handleFileChange, answeredData }) => {
  const { dictionary } = useContext(LanguageContext);
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);

  let acceptFileTypes = [];
  questionData.fileTypeAnswer.allowedFiles.forEach((element) => {
    element.split('#').forEach((mim) => {
      acceptFileTypes = [...acceptFileTypes, mim];
    });
  });

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
    handleFileChange(event.target.files);
    return event.target.files;
  };

  const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const lessThan2MB = async (files) => {
    await wait(500);
    return files && files.length > 0 ? files[0]?.size < 5000000 || 'Max file size allowed 5MB' : true;
  };

  const ext = (url) => {
    return url.substr(url.lastIndexOf('.') + 1);
  };

  const acceptedFormats = async (files) => {
    await wait(500);
    return files && files.length > 0 ? acceptFileTypes.includes(files[0]?.type) || questionData.fileTypeAnswer.typeErrorMessage : true;
  };

  const RenderFile = () => {
    if (imageArr.includes(ext(answeredData?.formAnswers?.uploadUrl))) {
      return (
        <Box
          component="img"
          sx={{
            height: 233,
            width: 350,
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 350, md: 250 }
          }}
          src={answeredData?.formAnswers?.uploadUrl}
        />
      );
    } else {
      return (
        <a href={answeredData?.formAnswers?.uploadUrl} target="_blank" rel="noreferrer noopener">
          Download file
        </a>
      );
    }
  };

  return (
    <FormControl component="fieldset" fullWidth>
      <Typography variant="h6">
        <QuestionText surveyFormQuestion={questionData} level={level} infoText={infoText} currentQuesIndex={currentQuestionIndex} />
      </Typography>
      <FormGroup>
        <Controller name={`${name}.id`} control={control} defaultValue={questionData.fileTypeAnswer.id} render={({ field: { onChange, value }, fieldState: { error }, formState }) => <input name={name} value={value} type="hidden" />} />
        <Paper elevation={0} className={classes.flexPaper}>
          <Controller
            {...{
              name: name,
              control: control,
              rules: {
                required: {
                  value: questionData.isRequired,
                  message: dictionary.surveyPopup.requiredQuestion
                },
                validate: {
                  lessThan2MB,
                  acceptedFormats
                }
              },
              render: ({ field: { onChange: onChangeFile, value }, fieldState: { error }, formState }) => {
                return (
                  <>
                    <label htmlFor="upload-file">
                      <input style={{ display: 'none' }} id="upload-file" type="file" onChange={(e) => onChangeFile(changeHandler(e))} />
                      <Button color="primary" variant="contained" component="span">
                        Upload button
                      </Button>
                    </label>
                    {value && value.length > 0 ? (
                      <div>
                        <p>Filename: {value[0].name}</p>
                        <p>Filetype: {value[0].type}</p>
                        <p>Size in bytes: {value[0].size}</p>
                      </div>
                    ) : (
                      <Paper elevation={0} style={{ paddingTop: '20px' }}>
                        {answeredData && answeredData?.formAnswers && answeredData?.formAnswers?.uploadUrl ? <RenderFile /> : <p>Select a file to show details</p>}
                      </Paper>
                    )}
                    <FormHelperText error={!!error}>{error ? error.message : null}</FormHelperText>
                  </>
                );
              }
            }}
          />
        </Paper>
      </FormGroup>
    </FormControl>
  );
};
