import { FormHelperText } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/zh-cn';
import { Fragment, useContext, useState } from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';

import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import bindClassNames from 'classnames/bind';
import styles from './index.module.scss';
import useComponentVisible from '../../../hooks/withClickOutside';
const cx = bindClassNames.bind(styles);

const MonthDropdown = ({ name, label, control, required, handleChange }) => {
  const { userLanguage, dictionary } = useContext(LanguageContext);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  moment.locale(userLanguage == 'zhCN' ? 'zh-cn' : userLanguage);
  const months = moment.monthsShort();

  return (
    <div className={cx('root-wrapper')} ref={ref}>
      <label htmlFor={name} className={cx('label')}>
        {label}
      </label>
      <label htmlFor={name} className={cx('label', 'screen-reader')}>
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        defaultValue={1}
        render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
          <Fragment>
            <Listbox
              value={value}
              onChange={(event) => {
                onChange(event);
                handleChange(event);
              }}>
              <div className={cx('wrapper')}>
                <Listbox.Button className={cx('button')} onClick={() => setIsComponentVisible(!isComponentVisible)}>
                  <span className={cx('screen-reader')}>{months[value] ? months[value] : 'Select Month'}</span>
                  <span className={cx('dob')}>{months[value] ? months[value] : 'Select Month'}</span>
                  <span className={cx('icon-wrapper')}>
                    <ChevronDownIcon className={cx(['icon', { 'icon-rotate': isComponentVisible }])} aria-hidden="true" />
                  </span>
                </Listbox.Button>
                <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                  <Listbox.Options className={cx('options')} onClick={() => setIsComponentVisible(false)}>
                    {months.map((value, index) => (
                      <Listbox.Option key={index} value={index} className={cx('dob-wrapper')}>
                        <span className={cx('dob')}>{value}</span>
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
            {/* TODO: Add custom error message */}
            <FormHelperText error={!!error}>{error ? error.message : null}</FormHelperText>
          </Fragment>
        )}
        rules={{
          required: {
            value: required,
            message: dictionary.landingPage.form.requiredField
          }
        }}
      />
    </div>
  );
};

const DaysDropdown = ({ name, label, control, required, month }) => {
  const { dictionary } = useContext(LanguageContext);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [selectedDay, setSelectedDay] = useState(1);

  const mom = new moment();
  mom.set('month', month);
  const days = mom.daysInMonth();
  const daysInMonth = Array.from({ length: days }, (_, i) => i + 1);

  return (
    <div className={cx('root-wrapper')} ref={ref}>
      <label htmlFor={name} className={cx('label')}>
        {label}
      </label>
      <label htmlFor={name} className={cx('label', 'screen-reader')}>
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        defaultValue={10}
        render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
          <Fragment>
            <Listbox value={value} onChange={onChange}>
              <div className={cx('wrapper')}>
                <Listbox.Button className={cx('button')} onClick={() => setIsComponentVisible(!isComponentVisible)}>
                  <span className={cx('screen-reader')}>{selectedDay ? selectedDay : 'Select Day'}</span>
                  <span className={cx('dob')}>{value ? value++ : 'Select Day'}</span>
                  <span className={cx('icon-wrapper')}>
                    <ChevronDownIcon className={cx(['icon', { 'icon-rotate': isComponentVisible }])} aria-hidden="true" />
                  </span>
                </Listbox.Button>
                <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                  <Listbox.Options className={cx('options')} onClick={() => setIsComponentVisible(false)}>
                    {daysInMonth.map((value, index) => (
                      <Listbox.Option key={index} value={value} className={cx('dob-wrapper')} onClick={() => setSelectedDay(value)}>
                        <span className={cx('dob')}>{value}</span>
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
            {/* TODO: Add custom error message */}
            <FormHelperText error={!!error}>{error ? error.message : null}</FormHelperText>
          </Fragment>
        )}
        rules={{
          required: {
            value: required,
            message: dictionary.landingPage.form.requiredField
          }
        }}
      />
    </div>
  );
};

const YearDropdown = ({ name, label, control, required }) => {
  const { dictionary } = useContext(LanguageContext);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [currentYear, setCurrentYear] = useState('');

  const y = new moment('1950-01-01').toDate();
  const year = y.getFullYear();
  const years = Array.from(new Array(70), (val, index) => index + year);

  return (
    <div className={cx('root-wrapper')} ref={ref}>
      <label htmlFor={name} className={cx('label')}>
        {label}
      </label>
      <label htmlFor={name} className={cx('label', 'screen-reader')}>
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        defaultValue={'1990'}
        render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
          <Fragment>
            <Listbox value={value} onChange={onChange}>
              <div className={cx('wrapper')} id={name}>
                <Listbox.Button className={cx('button')} onClick={() => setIsComponentVisible(!isComponentVisible)}>
                  <span className={cx('screen-reader')}>{currentYear ? currentYear : 'Select Year'}</span>
                  <span className={cx('dob')}>{currentYear ? currentYear : 'Select Year'}</span>
                  <span className={cx('icon-wrapper')}>
                    <ChevronDownIcon className={cx(['icon', { 'icon-rotate': isComponentVisible }])} aria-hidden="true" />
                  </span>
                </Listbox.Button>
                <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                  <Listbox.Options className={cx('options')} onClick={() => setIsComponentVisible(false)}>
                    {years.map((value, index) => (
                      <Listbox.Option key={index} value={value} className={cx('dob-wrapper')} onClick={() => setCurrentYear(value)}>
                        <span className={cx('dob')}>{value}</span>
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
            <FormHelperText error={!!error}>{error ? error.message : null}</FormHelperText>
          </Fragment>
        )}
        rules={{
          required: {
            value: required,
            message: dictionary.landingPage.form.requiredField
          }
        }}
      />
    </div>
  );
};

export { MonthDropdown, DaysDropdown, YearDropdown };
