import React, { useEffect, useState, useContext, useRef, Fragment } from 'react';
import { Card, CardContent, CardActions, Grid, Typography, Button, Backdrop, CircularProgress, useTheme, useMediaQuery, List, ListItem, ListItemText, Paper } from '@material-ui/core';
import SurveyCampaign1stLevelForm from '../common/SurveyCampaign1stLevelForm';
import { LanguageContext } from '../../contexts/LanguageContext';
import { useForm } from 'react-hook-form';
import { getSurveyPopupFirstLevelQuestions } from '../common/helpers';
import Errors from '../Errors';

import bindClassNames from 'classnames/bind';
import styles from './index.module.scss';
import useSurveyCampaignContext from '../../hooks/use-survey-campaign-context';
import { useAssessmentLandingPageQuery, useFetchPopupSurveyQuestionsQuery, useSubmitPopupSurveyQuestionsMutation } from '../../store';
const cx = bindClassNames.bind(styles);

const SurveyCampaign = (props) => {
  const { currentQuestion, setCurrentQuestion, selectedAnswer, questionsAll, setQuestionsAll, unattendedQuestions, setUnattendedQuestions, currentElement } = useSurveyCampaignContext();
  const { dictionary } = useContext(LanguageContext);

  const { data: retrieved, error, isLoading } = useFetchPopupSurveyQuestionsQuery(props.match.params);
  const { data: pinpointCampaign } = useAssessmentLandingPageQuery(props?.match?.params.campaignId);
  const [submitPopup, { data: submitted }] = useSubmitPopupSurveyQuestionsMutation();

  const [questions1stLevel, setQuestions1stLevel] = useState([]);
  const [submitAsItIs, setSubmitAsItIs] = useState(false);
  const [question, setQuestion] = useState('');
  const [formValues, setFormValues] = useState([]);
  const [buttonType, setButtonType] = useState('');
  const [active, setActive] = useState([]);
  const methods = useForm({ defaultValues: { questions: [] }, shouldUnregister: true });
  const ServicesRef = useRef(null);

  const { handleSubmit, setValue, reset } = methods;

  useEffect(() => {
    if (retrieved) {
      setQuestionsAll(retrieved['hydra:member']);
    }
  }, [retrieved]);

  useEffect(() => {
    if (questionsAll.length > 0) {
      const firstLevelQuestions = getSurveyPopupFirstLevelQuestions(questionsAll);
      setCurrentQuestion(0);
      setQuestions1stLevel(firstLevelQuestions);
      firstLevelQuestions.forEach((item, index) => {
        setActive((preState) => [...preState, { index: index, active: index === 0 ? true : false }]);
      });
    }
    reset();
    setFormValues([]);
  }, [questionsAll]);

  useEffect(() => {
    if (questions1stLevel.length > 0) {
      setQuestion(questions1stLevel[0]);
    }
  }, [questions1stLevel]);

  useEffect(() => {
    if (currentElement && currentElement.nextSibling) {
      currentElement.nextSibling.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [selectedAnswer]);

  const handleNext = (data) => {
    if (typeof formValues[currentQuestion] !== 'undefined') {
      let updateValues = formValues;
      updateValues[currentQuestion] = data.questions[currentQuestion];
      setFormValues(updateValues);
    } else {
      setFormValues((prevItems) => [...prevItems, data.questions[currentQuestion]]);
    }

    const nextQuetion = currentQuestion + 1;
    if (nextQuetion < questions1stLevel.length) {
      setQuestion(questions1stLevel[nextQuetion]);
      setCurrentQuestion(nextQuetion);
    }

    const isPresent = active.find((item) => item.index === nextQuetion);
    if (isPresent) {
      const remaining = active.filter((item) => item.index !== nextQuetion);
      setActive(() => [...remaining, { index: nextQuetion, active: true }]);
    }
  };

  const handlePrevious = () => {
    const previousQuetion = currentQuestion - 1;
    if (previousQuetion >= 0) {
      setQuestion(questions1stLevel[previousQuetion]);
      setCurrentQuestion(previousQuetion);
    }

    if (formValues.length > 0) {
      formValues.forEach((element, index) => {
        element.forEach((elmnt, key) => {
          if (elmnt.hasOwnProperty('fileTypeAnswer') && typeof elmnt.fileTypeAnswer !== 'undefined') {
            setValue(`questions[${index}][${key}].fileTypeAnswer`, elmnt.fileTypeAnswer);
          } else if (elmnt.hasOwnProperty('otherAnswerText') && typeof elmnt.otherAnswerText !== 'undefined') {
            setValue(`questions[${index}][${key}].otherAnswerText`, elmnt.otherAnswerText);
          } else if (elmnt.hasOwnProperty('formAnswers') && typeof elmnt.formAnswers !== 'undefined') {
            setValue(`questions[${index}][${key}].formAnswers`, elmnt.formAnswers);
          }
        });
      });
    }
    const isPresent = active.find((item) => item.index === currentQuestion);
    if (isPresent) {
      const remaining = active.filter((item) => item.index !== currentQuestion);
      setActive(() => [...remaining, { index: currentQuestion, active: false }]);
    }
  };

  const saveAndExit = (data) => {
    let formData = new FormData();
    let finalFormData = formValues;
    setButtonType('Save & Exit');
    if (typeof finalFormData[currentQuestion] !== 'undefined') {
      finalFormData[currentQuestion] = data.questions[currentQuestion];
    } else {
      finalFormData = [...finalFormData, data.questions[currentQuestion]];
    }
    formData.append(`button`, 'save & exit');
    if (finalFormData.length > 0) {
      finalFormData.forEach((element) => {
        element.forEach((elmnt, key) => {
          if (elmnt.hasOwnProperty('fileTypeAnswer') && typeof elmnt.fileTypeAnswer !== 'undefined' && elmnt.fileTypeAnswer.length > 0) {
            formData.append(`${key}`, elmnt.fileTypeAnswer[0]);
          } else if (elmnt.hasOwnProperty('formAnswers') && typeof elmnt.formAnswers !== 'undefined') {
            formData.append(`${key}`, JSON.stringify(elmnt));
          }
        });
      });
    }
    submitPopup({ params: props.match.params, formData: formData });
    setQuestionsAll([]);
  };

  useEffect(() => {
    if (submitted) {
      const unattended = submitted['hydra:member'].filter(
        (item) =>
          item.level === 0 &&
          item.parentSurveyFormQuestion === null &&
          (item.surveyFormQuestion.formAnswers.length > 0 ||
            item.surveyFormQuestion.dateTimeTypeAnswer !== null ||
            item.surveyFormQuestion.fileTypeAnswer !== null ||
            item.surveyFormQuestion.sliderLabelMax !== null ||
            item.surveyFormQuestion.sliderLabelMin !== null)
      );
      setUnattendedQuestions(unattended);
      if (unattended.length === 0) {
        setQuestions1stLevel([]);
      }
    }
  }, [submitted]);

  const submitAnswer = (data) => {
    let formData = new FormData();
    let finalFormData = formValues;
    setButtonType('submit');
    if (typeof finalFormData[currentQuestion] !== 'undefined') {
      finalFormData[currentQuestion] = data.questions[currentQuestion];
    } else {
      finalFormData = [...finalFormData, data.questions[currentQuestion]];
    }

    formData.append(`button`, 'submit');
    if (finalFormData.length > 0) {
      finalFormData.forEach((element) => {
        element.forEach((elmnt, key) => {
          if (elmnt.hasOwnProperty('fileTypeAnswer') && typeof elmnt.fileTypeAnswer !== 'undefined' && elmnt.fileTypeAnswer.length > 0) {
            formData.append(`${key}`, elmnt.fileTypeAnswer[0]);
          } else if (elmnt.hasOwnProperty('formAnswers') && typeof elmnt.formAnswers !== 'undefined') {
            formData.append(`${key}`, JSON.stringify(elmnt));
          }
        });
      });
    }
    submitPopup({ params: props.match.params, formData: formData });
  };

  const goBack = () => {
    setQuestionsAll(submitted['hydra:member']);
    setUnattendedQuestions([]);
    setCurrentQuestion(0);
    setSubmitAsItIs(false);
    setButtonType('');
  };

  const submitAsIs = () => {
    setSubmitAsItIs(true);
  };

  const theme = useTheme();
  const matchessm = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesmd = useMediaQuery(theme.breakpoints.up('md'));

  if (isLoading) {
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (error) {
    return (
      <Fragment>
        <Errors error={error} />
        {/* <div className={cx(['helper-message', 'error'])}>{error}</div> */}
      </Fragment>
    );
  }

  if (submitAsItIs) {
    return <div className={cx(['helper-message', 'thank-you'])}>{dictionary.surveyPopup.thankyoumsg}</div>;
  }

  if (questions1stLevel.length === 0 || buttonType === 'submit') {
    return <div className={cx(['helper-message', 'success'])}>{dictionary.surveyPopup.complitionmsg}</div>;
  }

  if (unattendedQuestions.length > 0 && buttonType === 'Save & Exit') {
    return (
      <Card style={{ alignItems: 'center' }}>
        <CardContent style={{ height: matchessm ? 480 : 540, overflow: 'auto' }}>
          <Grid container spacing={3} style={{ padding: '10px 20px' }}>
            <Typography variant="h6">
              {dictionary.surveyPopup.following} {unattendedQuestions.length} {dictionary.surveyPopup['question(s) not answered yet']}
            </Typography>
          </Grid>
          <List dense={true}>
            {unattendedQuestions.map((item, index) => (
              <ListItem key={item.id}>
                <ListItemText primary={`${index + 1}) ${item.surveyFormQuestion.label}`} />
              </ListItem>
            ))}
          </List>
        </CardContent>
        <CardActions style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #ddd' }}>
          <Button variant="contained" color="primary" size="small" onClick={goBack}>
            {dictionary.surveyPopup.yesGoBack}
          </Button>
          <Button variant="contained" color="primary" size="small" onClick={submitAsIs}>
            {dictionary.surveyPopup.submitAsIs}
          </Button>
        </CardActions>
      </Card>
    );
  }

  if (questionsAll.length > 0 && question.hasOwnProperty('surveyFormQuestion') && dictionary.surveyPopup.workplaceDEIAssessment) {
    return (
      <div className={cx('wrapper')}>
        <div className={cx('wrapper-resize')}>
          <div className={cx('upper')}>
            <h6 className={cx('heading')}>{pinpointCampaign?.campaignName}</h6>
            <div>
              {currentQuestion >= 1 && currentQuestion + 1 < questions1stLevel.length && (
                <button className={cx('button', 'transparent', 'cap')} onClick={handleSubmit(saveAndExit)}>
                  {dictionary.surveyPopup.savenexit}
                </button>
              )}
            </div>
          </div>

          <div className={cx('progress-wrapper')}>
            {/* {questions1stLevel.map((item, index) => {
              const currenQues = active.find((item) => item.index === index);
              return <div key={index} className={cx('progress-bar', { 'active-bar': currenQues.active ? true : false })} />;
            })} */}
            {console.log(currentQuestion)}
            <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
              <div className="bg-[#3A6CC8] text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{ width: `${(currentQuestion / questions1stLevel.length) * 100}%`, height: '8px' }}></div>
            </div>
          </div>

          <div className={cx('controls')}>
            {questions1stLevel.length > 1 && (
              <button className={cx('button', 'transparent', 'col')} onClick={handlePrevious}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className={cx('icon')}>
                  <path fillRule="evenodd" d="M18 10a.75.75 0 01-.75.75H4.66l2.1 1.95a.75.75 0 11-1.02 1.1l-3.5-3.25a.75.75 0 010-1.1l3.5-3.25a.75.75 0 111.02 1.1l-2.1 1.95h12.59A.75.75 0 0118 10z" clipRule="evenodd" />
                </svg>
                <span>{dictionary.surveyPopup.previous}</span>
              </button>
            )}

            <div className={cx('questionnaire')}>
              {`${Math.round((currentQuestion / questions1stLevel.length) * 100)}%`}
              {/* {currentQuestion + 1}/{questions1stLevel.length} */}
            </div>
          </div>
          <div ref={ServicesRef} className={cx('survey-first-level')}>
            <SurveyCampaign1stLevelForm question={question} currentQuestion={currentQuestion} methods={methods} />
          </div>
        </div>

        <CardActions style={{ display: 'flex', justifyContent: currentQuestion + 1 === questions1stLevel.length ? 'space-between' : 'space-between' }}>
          {currentQuestion + 1 === questions1stLevel.length && (
            <button className={cx('button')} onClick={handleSubmit(submitAnswer)}>
              <span>{dictionary.surveyPopup.submit}</span>
            </button>
          )}
          {currentQuestion + 1 < questions1stLevel.length && (
            <button className={cx('button', 'semi', 'col', 'space-1')} onClick={handleSubmit(handleNext)}>
              <span>{dictionary.surveyPopup.next}</span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className={cx('icon', 'rotate-180')}>
                <path fillRule="evenodd" d="M18 10a.75.75 0 01-.75.75H4.66l2.1 1.95a.75.75 0 11-1.02 1.1l-3.5-3.25a.75.75 0 010-1.1l3.5-3.25a.75.75 0 111.02 1.1l-2.1 1.95h12.59A.75.75 0 0118 10z" clipRule="evenodd" />
              </svg>
            </button>
          )}
        </CardActions>
      </div>
    );
  } else {
    return null;
  }
};

export default SurveyCampaign;
