import KanarysLogo from '../../assets/images/ki4uQJtG-image.png';
import Lottie from 'react-lottie';
import ErrorLottie from '../../assets/lotties/error.json';

import bindClassNames from 'classnames/bind';
import styles from './index.module.scss';
import { useLocation } from 'react-router-dom';
const cx = bindClassNames.bind(styles);

const PageNotFound = () => {
  const location = useLocation();

  return (
    <div className={cx('wrapper')}>
      <img src={KanarysLogo} alt="Kanarys Logo" className={cx('image')} />
      <h3 className={cx('heading', 'text-color')}>
        We don&apos;t have a page for <span className={cx('path')}>{location.pathname.replace(/\/$/, '').replace(/^\//, '')}</span>
      </h3>
      <p className={cx('text-color')}>
        But if you think it should exist, please let us know at{' '}
        <a href="mailto:support@kanarys.com" className={cx('support')}>
          support@kanarys.com
        </a>
      </p>
      <p className={cx('text-color')}>
        or you can go back to{' '}
        <a href="/" className={cx('redirect')}>
          Home
        </a>
      </p>
      <Lottie
        isClickToPauseDisabled={true}
        options={{
          loop: true,
          autoplay: true,
          animationData: ErrorLottie,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }}
        height={400}
        width={400}
      />
    </div>
  );
};

export default PageNotFound;
