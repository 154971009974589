import React, { Fragment, useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup, FormHelperText } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { styled } from '@material-ui/core/styles';

import bindClassNames from 'classnames/bind';
import styles from './index.module.scss';
import QuestionText from '../questionText';
import { useLocation } from 'react-router-dom';
import useSurveyCampaignContext from '../../../hooks/use-survey-campaign-context';
const cx = bindClassNames.bind(styles);

const FormControlLabelStyled = styled(FormControlLabel)({
  '& .MuiFormControlLabel-label': {
    width: '100%',
    display: 'flex',
    border: '1px solid #E6E6E6',
    margin: '5px 0px 5px 0px',
    borderRadius: '5px',
    cursor: 'pointer'
  },
  '&.Mui-checked.MuiFormControlLabel-label': {
    backgroundColor: '#F5F5F5'
  },
  '& input[type="radio"]:checked+.MuiFormControlLabel-label': {
    backgroundColor: '#000'
  }
});

const RadioInput = ({ id, name, control, infoText, required, level, questionData, currentQuestionIndex, handleChange, answeredData }) => {
  const { setCurrentElement } = useSurveyCampaignContext();
  // console.log(questionData.formAnswers);
  // questionData.formAnswers.sort((a, b) => parseFloat(a.postion) - parseFloat(b.position));
  const [check, setChecked] = useState(null);
  const [formSection, setFormSection] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.match(/\/form\//)) {
      setFormSection(true);
    }
  }, [location]);

  useEffect(() => {
    if (answeredData) {
      setChecked(answeredData.formAnswers);
    }
  }, [answeredData]);

  return (
    <Fragment>
      <QuestionText surveyFormQuestion={questionData} level={level} infoText={infoText} currentQuesIndex={currentQuestionIndex} />
      {/* <h6 className={cx('question')}>{questionData.label}</h6> */}
      <Controller
        name={name}
        control={control}
        defaultValue={answeredData ? answeredData?.formAnswers : ''}
        render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
          <React.Fragment>
            <RadioGroup
              value={value}
              className={cx('radio-group', { 'form-section': formSection })}
              onChange={(event) => {
                event.preventDefault();
                setCurrentElement(event.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement);
                onChange(event.target.value);
                setChecked(event.target.value);
                handleChange({ id: id, surveyFormQuestionId: questionData.id, level: level, value: questionData.formAnswers.filter((item) => item.id == event.target.value) });
              }}>
              {questionData.formAnswers.map((option) => (
                <FormControlLabelStyled
                  value={option.id.toString()}
                  label={
                    <p
                      className={cx('label')}
                      style={{
                        backgroundColor: check == option.id.toString() ? '#EBF0FA' : '',
                        border: check == option.id.toString() ? '2px solid #3A6CC8' : '',
                        borderRadius: check == option.id.toString() ? '5px' : ''
                      }}>
                      {option.value}
                    </p>
                  }
                  control={<Radio className={cx('radio-control')} />}
                  key={option['id']}
                />
              ))}
            </RadioGroup>
            <FormHelperText error={!!error}>{error ? error.message : null}</FormHelperText>
          </React.Fragment>
        )}
        rules={required}
      />
    </Fragment>
  );
};

export default RadioInput;
