import { useState, useEffect } from 'react';
const SERVERURL = process.env.REACT_APP_API_SERVER;

export default function useFindUser() {
  const [user, setUser] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    async function findUser() {
      const request = new Request(`${SERVERURL}/user/me`, {
        method: 'GET',
        headers: new Headers(localStorage.getItem('token') ? { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } : { 'Content-Type': 'application/json' })
      });

      return await fetch(request)
        .then((response) => {
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }
          setLoading(false);
          return response.json();
        })
        .then(async (res) => {
          setUser(res);
          setLoading(false);
        });
    }
    findUser();
  }, []);
  return { user, setUser, isLoading };
}
