import { Radio, FormGroup, FormLabel, FormControlLabel, RadioGroup, FormHelperText } from '@material-ui/core';
import { useContext } from 'react';
import { Controller } from 'react-hook-form';
import { LanguageContext } from '../../contexts/LanguageContext';

const RadioInput = ({ label, name, required, control, options, answeredOption, handleChange }) => {
  const { dictionary } = useContext(LanguageContext);
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
      <FormLabel component="div" style={{ width: '25%' }}>
        {label}
      </FormLabel>
      <FormGroup style={{ width: '75%' }}>
        <Controller
          name={name}
          control={control}
          defaultValue={answeredOption?.value ? answeredOption?.value : ''}
          render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
            <div>
              <RadioGroup
                row
                value={value}
                onChange={(event) => {
                  event.preventDefault();
                  onChange(event.target.value);
                  handleChange(event.target.value);
                }}>
                {options.map((option) => (
                  <FormControlLabel value={option.value} control={<Radio color="primary" />} label={option.label} key={`${option['@id']}_${option.label}`} />
                ))}
              </RadioGroup>
              <FormHelperText error={!!error}>{error ? error.message : null}</FormHelperText>
            </div>
          )}
          rules={{
            required: {
              value: required,
              message: dictionary.surveyPopup.requiredQuestion
            }
          }}
        />
      </FormGroup>
    </div>
  );
};

export default RadioInput;
