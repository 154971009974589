import { Controller } from 'react-hook-form';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { Fragment, useContext } from 'react';

import bindClassNames from 'classnames/bind';
import styles from './index.module.scss';
const cx = bindClassNames.bind(styles);

const EmailTextInput = ({ label, name, infoText, control, required, type, placeholder, tooltip, ...props }) => {
  const { dictionary } = useContext(LanguageContext);
  return (
    <div className={cx('root-wrapper')}>
      <Controller
        name={name}
        control={control}
        defaultValue={''}
        render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
          <Fragment>
            <label htmlFor={placeholder ? placeholder : label} className={cx('label')}>
              {label}
              {tooltip && <span className={cx('label-spacer')}>{tooltip}</span>}
            </label>
            <input type="text" onChange={onChange} value={value} id={placeholder ? placeholder : label} className={cx('input')} placeholder={placeholder ? placeholder : label} />
            {!!error && <p className={cx('error')}>{error ? error.message : null}</p>}
          </Fragment>
        )}
        rules={{
          required: {
            value: required,
            message: dictionary.landingPage.form.requiredField
          },
          pattern: {
            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: dictionary.landingPage.form.validEmail
          }
        }}
        {...props}
      />
    </div>
  );
};

export default EmailTextInput;
