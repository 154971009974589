import React, { useEffect, useState, useContext, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Backdrop, CircularProgress, Grid } from '@material-ui/core';
import ShowMoreText from 'react-show-more-text';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import LanguageSelector from '../common/languageSelector';
import { LanguageContext } from '../../contexts/LanguageContext';
import CommonTextInput from '../common/commonTextInput';
import { DaysDropdown, MonthDropdown, YearDropdown } from '../common/monthDayYearInput';
import AgreeCheckBoxInput from '../common/agreeCheckBoxInput';
import CompanyDropdown from '../common/companyDropdown';
import PositionDropdown from '../common/positionDropdown';
import DepartmentDropdown from '../common/departmentDropdown';
import GenderDropdown from '../common/genderDropdown';
import RaceDropdown from '../common/raceDropdown';
import EthnicityDropdown from '../common/ethnicityDropdown';
import FALLBACK_IMAGE from '../../assets/images/ki4uQJtG-image.png';
import EmailTextInput from '../common/emailTextInput';
import Errors from '../Errors';

import SurveyCampaignContext from '../../contexts/SurveyCampaignContext';
import { useAssessmentLandingPageQuery, useAssessmentLoginMutation } from '../../store';
import bindClassNames from 'classnames/bind';
import styles from './surverycampaignlanding.module.scss';
const cx = bindClassNames.bind(styles);

const useStyles = makeStyles(() => ({
  cardFirst: {
    borderRadius: 0,
    backgroundColor: '#EBF0FA',
    boxShadow: 'none',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cardSecond: {
    borderRadius: 0,
    boxShadow: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  media: {
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center'
  },
  rightSection: {
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 'display'
  },
  cardContent: {
    maxWidth: '65%',
    width: '100%',
    margin: '0 auto',
    padding: '0 10px !important'
  },
  campaignName: {
    fontWeight: 600,
    fontSize: '40px',
    lineHeight: '125%'
  },
  containerClass: {
    display: 'flex'
  }
}));

const SurveyCampaignLanding = (props) => {
  const { data, error, isLoading } = useAssessmentLandingPageQuery(props?.match?.params.campaignId);
  const [assessmentLogin, result] = useAssessmentLoginMutation(props?.match?.params.campaignId);
  const { control, handleSubmit, unregister, watch } = useForm({ defaultValues: { month: 1 } });
  const { dictionary } = useContext(LanguageContext);
  const { setAssessmentUser } = useContext(SurveyCampaignContext);
  const [monthValue, setMonthValue] = useState(0);
  let history = useHistory();

  const classes = useStyles();

  const [stepOne, setStepOne] = useState(false);

  const race = watch('race');
  const gender = watch('gender');

  React.useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'gender' && value.gender !== 4) {
        unregister('genderOther');
      }
      if (name === 'race' && value.race !== 10) {
        unregister('raceOther');
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleOnChange = (value) => {
    setMonthValue(value);
  };

  const handleStepOne = () => {
    setStepOne(true);
  };

  const handleLogin = (data) => {
    data.campaignId = props?.match?.params.campaignId;
    assessmentLogin(data);
  };

  useEffect(() => {
    if (result.data) {
      const campaign = result.data;
      if (campaign.formType === 'Popup') {
        localStorage.setItem('loggedUser', JSON.stringify(campaign.user));
        history.push(decodeURIComponent(`/survey-campaign/campaign/${campaign.user.id}/${campaign.user.company.id}/${campaign.pinpointCampaign.id}`));
      } else {
        history.push(decodeURIComponent(`/survey-campaign/form/${campaign.user.id}/${campaign.user.company.id}/${campaign.pinpointCampaign.id}`));
      }
    }
  }, [result]);

  let anchorRef = React.createRef();
  let htmlContents;
  if (isLoading) {
    htmlContents = (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else if (error) {
    htmlContents = <Errors error={error} />;
  } else {
    if (data.campaignType === 'Company') {
      htmlContents = (
        <Fragment>
          <div className={cx('wrapper')}>
            <div className={cx('image-wrapper')}>
              <img src={data.image ? data?.image?.imageUrl : FALLBACK_IMAGE} alt={data?.image?.imageAlt} className={cx('image')} />
            </div>

            <div className={cx('content-wrapper')}>
              <Card className={classes.cardSecond}>
                <div className={cx('content')}>
                  <Grid container spacing={0} alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
                    <form onSubmit={handleSubmit(handleLogin)} style={{ width: '100%' }} className={cx('form')}>
                      <Grid item>
                        <h6 className={cx('heading')}>{data?.campaignName}</h6>
                      </Grid>
                      <Grid item>
                        <p
                          className={cx('description')}
                          dangerouslySetInnerHTML={{
                            __html: data?.image?.loginTextTop
                          }}
                        />
                      </Grid>

                      <div>
                        {!stepOne && (
                          <Fragment>
                            <LanguageSelector name="language" label={dictionary.landingPage.form.language} control={control} required={false} />
                          </Fragment>
                        )}
                      </div>

                      <div className={cx('terms-spacer')}>
                        {!stepOne && (
                          <AgreeCheckBoxInput
                            control={control}
                            name="privacy"
                            required={true}
                            label={
                              <ShowMoreText
                                lines={2}
                                more={`Show ${dictionary.landingPage.termCondition.more}`}
                                less={`Show ${dictionary.landingPage.termCondition.less}`}
                                className={`content-css ${cx(['show-more-less', 'text-wrapper'])}`}
                                anchorClass={cx(['show-more-less', 'anchor'])}
                                expanded={false}
                                truncatedEndingComponent={'... '}>
                                <strong>{dictionary.landingPage.termCondition.sectionOne}</strong>
                                <span>{dictionary.landingPage.termCondition.sectionTwo}</span>
                                <strong>
                                  <Link to="/privacy-policy" innerRef={anchorRef} target="_blank" rel="noopener noreferrer">
                                    {' '}
                                    {dictionary.landingPage.termCondition.sectionThree}.{' '}
                                  </Link>
                                </strong>
                                {dictionary.landingPage.termCondition.sectionFour}{' '}
                                <strong>
                                  <a href="mailto:CustomerSuccess@Kanarys.com" className={cx('mail')}>
                                    CustomerSuccess@Kanarys.com.
                                  </a>
                                </strong>
                              </ShowMoreText>
                            }
                          />
                        )}
                      </div>
                      <div>{stepOne && <CommonTextInput label={dictionary.landingPage.form.UniqueIDPin} name="uniqueId" placeholder="" control={control} required={{ required: dictionary.landingPage.form.requiredField }} />}</div>
                      <Grid container spacing={2}>
                        {stepOne && (
                          <Grid item xs={6}>
                            <DaysDropdown label={dictionary.landingPage.form.BirthdayDay} name={'day'} control={control} month={monthValue} required={true} />
                          </Grid>
                        )}
                        {stepOne && (
                          <Grid item xs={6}>
                            <MonthDropdown label={dictionary.landingPage.form.BirthdayMonth} name={'month'} control={control} handleChange={handleOnChange} required={true} />
                          </Grid>
                        )}
                      </Grid>
                      <Grid item style={{ minHeight: '50vm', margin: '15px 0px' }}>
                        {!stepOne && (
                          <button className={cx('step-one-button')} onClick={handleSubmit(handleStepOne)}>
                            {dictionary.landingPage.form.LetsBegin}{' '}
                          </button>
                        )}
                        {stepOne && (
                          <button className={cx('step-one-button')} type="submit">
                            {dictionary.landingPage.form.Login}{' '}
                          </button>
                        )}
                      </Grid>
                    </form>
                  </Grid>
                </div>
              </Card>
            </div>
          </div>
        </Fragment>
      );
    } else if (data.campaignType === 'Strategic Partner') {
      htmlContents = (
        <div className={cx('wrapper')}>
          <div className={cx('image-wrapper')}>
            <img src={data.image ? data?.image?.imageUrl : FALLBACK_IMAGE} alt={data?.image?.imageAlt} className={cx('image')} />
          </div>

          <div className={cx('content-wrapper')}>
            <Card className={classes.cardSecond}>
              <div className={cx('content')}>
                <Grid container spacing={0} alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
                  <form onSubmit={handleSubmit(handleLogin)} style={{ width: '100%' }} className={cx('form')}>
                    <Grid item>
                      <h6 className={cx('heading')}>{data?.campaignName}</h6>
                    </Grid>
                    <Grid item>
                      <p
                        className={cx('description')}
                        dangerouslySetInnerHTML={{
                          __html: data?.image?.loginTextTop
                        }}
                      />
                    </Grid>
                    <Grid item>{stepOne && <EmailTextInput label={dictionary.landingPage.form.Email} name="useremail" control={control} type="email" required={true} />}</Grid>
                    <Grid item>{stepOne && data.isPositionSelection && <PositionDropdown label={dictionary.landingPage.form.Position} name="position" control={control} required={{ required: dictionary.landingPage.form.requiredField }} />}</Grid>
                    <Grid item>
                      {stepOne && data.isDepartmentSelection && <DepartmentDropdown label={dictionary.landingPage.form.Department} name="userdepartment" control={control} required={{ required: dictionary.landingPage.form.requiredField }} />}
                    </Grid>

                    <Grid item>
                      {stepOne && data.isGenderSelection && <GenderDropdown label={dictionary.landingPage.form.Gender} name="gender" control={control} required={{ required: dictionary.landingPage.form.requiredField }} />}
                      {stepOne && data.isGenderSelection && gender === 4 && <CommonTextInput label="Gender Other" name="genderOther" control={control} required={{ required: dictionary.landingPage.form.requiredField }} />}
                    </Grid>
                    <Grid item>
                      {stepOne && data.isRaceSelection && <RaceDropdown label={dictionary.landingPage.form.Race} name="race" control={control} required={{ required: dictionary.landingPage.form.requiredField }} />}
                      {stepOne && data.isRaceSelection && race === 10 && <CommonTextInput label="Race Other" name="raceOther" control={control} required={{ required: dictionary.landingPage.form.requiredField }} />}
                    </Grid>
                    <Grid item>
                      {stepOne && data.isEthnicitySelection && <EthnicityDropdown label={dictionary.landingPage.form.Ethnicity} name="ethnicity" control={control} required={{ required: dictionary.landingPage.form.requiredField }} />}
                      {stepOne && <CompanyDropdown label={dictionary.landingPage.form.Company} name="company" control={control} required={true} />}
                    </Grid>
                    <Grid item>{stepOne && data.isLanguageSelection && <LanguageSelector name="language" label={dictionary.landingPage.form.language} control={control} required={false} />}</Grid>
                    {data.isBirthDateSelection && (
                      <Grid container spacing={2}>
                        {stepOne && (
                          <Grid item xs={4}>
                            <MonthDropdown label={dictionary.landingPage.form.BirthdayMonth} name={'month'} control={control} handleChange={handleOnChange} required={true} />
                          </Grid>
                        )}
                        {stepOne && (
                          <Grid item xs={4}>
                            <DaysDropdown label={dictionary.landingPage.form.BirthdayDay} name={'day'} control={control} month={monthValue} required={true} />
                          </Grid>
                        )}
                        {stepOne && (
                          <Grid item xs={4}>
                            <YearDropdown label={dictionary.landingPage.form.BirthdayYear} name={'year'} control={control} required={true} />
                          </Grid>
                        )}
                      </Grid>
                    )}
                    <Grid item>
                      {!stepOne && (
                        <button disabled={isLoading} className={cx('step-one-button')} onClick={handleSubmit(handleStepOne)}>
                          {dictionary.landingPage.form.LetsBegin}{' '}
                        </button>
                      )}
                      {stepOne && (
                        <button className={cx('step-one-button')} type="submit">
                          {dictionary.landingPage.form.Login}{' '}
                        </button>
                      )}
                    </Grid>
                  </form>
                </Grid>
              </div>
            </Card>
          </div>
        </div>
      );
    }
  }
  return <>{htmlContents}</>;
};

export default SurveyCampaignLanding;
