import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Grid } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(2.8)'
  },
  mainGrid: {
    marginTop: theme.spacing(3)
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
}));
const About = () => {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Grid container spacing={10} className={classes.mainGrid}>
      <Grid item xs={12} md={8}>
        <Container className={classes.root}>
          <Typography variant="h2" color="textSecondary" gutterBottom>
            About Us
          </Typography>
          <Typography variant="h5" component="h2">
            be{bull}nev{bull}o{bull}lent
          </Typography>
          <Typography color="textSecondary">adjective</Typography>
          <Typography variant="body2" component="p">
            well meaning and kindly.
            <br />
            {'"a benevolent smile"'}
          </Typography>
        </Container>
      </Grid>
    </Grid>
  );
};

export default About;
