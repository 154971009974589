import { Typography, FormGroup, FormControl } from '@material-ui/core';
import MloeaTextInput from './mloeatextInput';
import QuestionText from './questionText';
import { Controller } from 'react-hook-form';
import useSurveyCampaignContext from '../../hooks/use-survey-campaign-context';

const MultiLineOpenEnded = ({ id, name, control, infoText, required, level, questionData, currentQuestionIndex, handleChange, answeredData }) => {
  questionData.formAnswers.sort((a, b) => parseFloat(a.rank) - parseFloat(b.rank));
  const { setCurrentElement } = useSurveyCampaignContext();
  return (
    <FormControl component="fieldset" fullWidth>
      <Typography variant="h6" style={{ alignItems: 'center' }}>
        <QuestionText surveyFormQuestion={questionData} level={level} infoText={infoText} currentQuesIndex={currentQuestionIndex} />
      </Typography>
      <FormGroup>
        {questionData.formAnswers.map((option, index) => {
          const answerVal = answeredData && answeredData?.formAnswers && answeredData?.formAnswers.length > 0 ? answeredData?.formAnswers.find((item) => item?.id === option.id) : null;
          return (
            <div key={`${name}-${index}-${option['@id']}`}>
              <Controller name={`${name}[${index}].id`} control={control} defaultValue={option.id} render={({ field: { onChange, value }, fieldState: { error }, formState }) => <input name={name} value={value} type="hidden" />} />
              <MloeaTextInput name={`${name}[${index}].value`} type="textarea" control={control} required={required} label={option?.value} key={option['@id']} answerText={answerVal?.answerText} handleChange={handleChange} />
            </div>
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

export default MultiLineOpenEnded;
