import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const MIME_TYPE = 'application/ld+json';
const ENTRYPOINT = process.env.REACT_APP_API_SERVER || '';
let lang = localStorage.getItem('rcml-lang');
let token = localStorage.getItem('token');

const pagesApi = createApi({
  reducerPath: 'pages',
  baseQuery: fetchBaseQuery({
    baseUrl: ENTRYPOINT,
    prepareHeaders: (headers, { state }) => {
      headers.set('X-LOCALE', lang || 'en');
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      if (null === headers.get('Accept')) headers.set('Accept', MIME_TYPE);
    }
  }),
  endpoints(builder) {
    return {
      cmsPage: builder.query({
        query: (pageId) => `pages/${pageId}`
      })
    };
  }
});
export const { useCmsPageQuery } = pagesApi;
export { pagesApi };
