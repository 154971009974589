import React, { useContext, useState, Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { FormControl } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { LanguageContext } from '../../../contexts/LanguageContext';

import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';

import bindClassNames from 'classnames/bind';
import styles from './index.module.scss';
import { useFetchCompaniesQuery } from '../../../store/apis/companiesApi';
const cx = bindClassNames.bind(styles);

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    margin: theme.spacing(0),
    minWidth: '30%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  }
}));

const CompanyDropdown = ({ name, label, control, required }) => {
  const classes = useStyles();
  const { dictionary } = useContext(LanguageContext);
  const [selected, setSelected] = useState(null);
  const [query, setQuery] = useState('');
  const [inputValue, setInputValue] = useState('');
  const { data, error, isLoading } = useFetchCompaniesQuery(inputValue);

  let companyCombohtml;

  if (isLoading) {
    companyCombohtml = <div className={cx('not-available')}>...dropdown loading</div>;
  } else if (error) {
    companyCombohtml = <div className={cx('not-available')}>there is error in loading.</div>;
  } else {
    const companies = data['hydra:member'].filter((company) => company.name !== '');
    let filteredCompanies = query === '' ? companies : companies.filter((company) => company.name.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')));
    if (!filteredCompanies || (filteredCompanies.length === 0 && query !== '')) {
      companyCombohtml = <div className={cx('not-available')}>No Available Positions.</div>;
    } else {
      companyCombohtml = filteredCompanies.map((company) => (
        <Combobox.Option key={company.id} className={({ active }) => `relative cursor-pointer select-none py-2 pl-5 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'}`} value={company}>
          {({ selected, active }) => (
            <>
              <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                <span className="flex items-center justify-start w-full space-x-3">
                  <img loading="lazy" width="20" src={company.logoUrl} srcSet={`${company.logoUrl} 2x`} alt="" />
                  <span className={cx('lists', 'spacer')}>{company.name ? company.name : ''}</span>
                </span>
              </span>
              {selected ? (
                <span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'}`}>
                  <CheckIcon className="w-5 h-5" aria-hidden="true" />
                </span>
              ) : (
                ''
              )}
            </>
          )}
        </Combobox.Option>
      ));
    }
  }

  return (
    <FormControl className={classes.formControl} variant="filled" fullWidth>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
          <div className={cx('root-wrapper')}>
            <Combobox
              value={value.name || ''}
              onChange={(event) => {
                onChange(event);
                setSelected(event.name);
              }}>
              <Combobox.Label className={cx('label')}>{dictionary.landingPage.form.ChooseCompany}</Combobox.Label>
              <div className={cx('wrapper')}>
                <Combobox.Input
                  className={cx('input')}
                  placeholder={dictionary.landingPage.form.ChooseCompany}
                  displayValue={value.name}
                  autoComplete="off"
                  onChange={(event) => {
                    setQuery(event.target.value);
                    setInputValue(event.target.value);
                  }}
                />
                <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" afterLeave={() => setQuery('')}>
                  <Combobox.Options className={cx('options')}>{companyCombohtml}</Combobox.Options>
                </Transition>
              </div>
            </Combobox>
            {!!error && <p className={cx('error')}>{error ? error.message : null}</p>}
          </div>
        )}
        onChange={([event, data]) => {
          return data;
        }}
        name={name}
        control={control}
        defaultValue={''}
        rules={{
          required: {
            value: required,
            message: dictionary.landingPage.form.requiredField
          }
        }}
      />
    </FormControl>
  );
};
export default CompanyDropdown;
