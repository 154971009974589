export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const uniqueObjectedArray = (questionsAll, conditionalQuestion, level) => {
  const ques2ndLevel = questionsAll.filter((ques) => ques.level === level && conditionalQuestion.includes(ques.surveyFormQuestion['@id']));
  ques2ndLevel.sort((a, b) => parseFloat(a.rank) - parseFloat(b.rank));
  return [...new Map(ques2ndLevel.map((item) => [item['surveyFormQuestion']['@id'], item])).values()];
};

export const generateKey = (question) => {
  return `${question.id}@${question.rank}`;
};

export const isMatched = (selectedAnswer2ndLevel, question) => {
  const selection = selectedAnswer2ndLevel.find((item) => item.questionId === question.id);
  return selection ? true : false;
};

export const isAnswerQuestionMatched = (selectedAnswer2ndLevel, question) => {
  const surveyFormQuestion = question['@id'];
  const selectQues = [];
  if (selectedAnswer2ndLevel.length > 0) {
    selectedAnswer2ndLevel.forEach((element) => {
      if (element !== null && typeof element?.value === 'object') element?.value?.forEach((item) => item.other && selectQues.push(item.formQuestion));
    });
    return selectQues.includes(surveyFormQuestion);
  } else {
    return false;
  }
};

export const isAnswerQuestionMatched3rd = (selectedAnswer3rdLevel, question) => {
  const surveyFormQuestion = question['@id'];
  const selectQues = [];
  if (selectedAnswer3rdLevel.length > 0) {
    selectedAnswer3rdLevel.forEach((element) => {
      if (element !== null && typeof element?.value === 'object') element?.value?.forEach((item) => item.other && selectQues.push(item.formQuestion));
    });
    return selectQues.includes(surveyFormQuestion);
  } else {
    return false;
  }
};

export const isAnswerQuestionMatched4rth = (selectedAnswer4rthLevel, question) => {
  const surveyFormQuestion = question['@id'];
  const selectQues = [];
  if (selectedAnswer4rthLevel.length > 0) {
    selectedAnswer4rthLevel.forEach((element) => {
      if (element !== null && typeof element?.value === 'object') element?.value?.forEach((item) => item.other && selectQues.push(item.formQuestion));
    });
    return selectQues.includes(surveyFormQuestion);
  } else {
    return false;
  }
};

export const getConditionalQuestionIds = (selectedAnswers = []) => {
  let conditionalQuestionsArr = [];
  if (selectedAnswers.length > 0) {
    selectedAnswers.forEach((selectedAnswer) => {
      const { value } = selectedAnswer;
      if (Array.isArray(value) && value.length > 0) {
        value.forEach((element) => {
          if (element.formQuestions.length > 0) {
            conditionalQuestionsArr = [...conditionalQuestionsArr, ...element.formQuestions];
          }
        });
      }
    });
  }
  return conditionalQuestionsArr;
};

export const getConditionalQuestionWithStateIds = (value = []) => {
  let conditionalQuestionsArr = [];
  if (Array.isArray(value) && value.length > 0) {
    value.forEach((element) => {
      if (element.formQuestions.length > 0) {
        conditionalQuestionsArr = [...conditionalQuestionsArr, ...element.formQuestions];
      }
    });
  }
  return conditionalQuestionsArr;
};

export const checkIfOtherOptionChecked = (answer) => {
  if (answer.length > 0) {
    const hasOther = answer.find((ans) => ans.other);
    return hasOther ? true : false;
  } else {
    return false;
  }
};

export const updateQuestionData = (allQuestionsData, defaultQuestionValues) => {
  const newArr = allQuestionsData.map((obj) => {
    if (obj.id === defaultQuestionValues.id) {
      return { ...obj, formAnswers: defaultQuestionValues.formAnswers };
    }
    return obj;
  });
  return newArr;
};

export const getInputNameToUnregister = (question, questionsAll, preState, callBack) => {
  let inputToUnregister = [];
  const previouConditionQuestion = uniqueObjectedArray(questionsAll, getConditionalQuestionWithStateIds(preState.value), preState.level + 1);
  previouConditionQuestion.forEach((item) => {
    switch (question.surveyFormQuestion?.type) {
      case 'choice-unique-expanded':
      case 'choice-true-false':
      case 'choice-multiple-expanded':
      case 'choice-multiple-collapsed':
      case 'choice-unique-collapsed':
      case 'ranking':
      case 'multi-line-open-ended':
      case 'matrix':
        inputToUnregister = [...inputToUnregister, `questions[${item.id}].formAnswers`];
        break;
      case 'textarea':
      case 'text':
        inputToUnregister = [...inputToUnregister, `questions[${item.id}].value`];
        break;
      case 'file-upload':
        inputToUnregister = [...inputToUnregister, `questions[${item.id}].fileTypeAnswer`];
        break;
      case 'date-time':
        inputToUnregister = [...inputToUnregister, `questions[${item.id}].dateTimeTypeAnswer`];
        break;
      case 'slider':
        inputToUnregister = [...inputToUnregister, `questions[${item.id}].slider`];
        break;
      default:
        inputToUnregister = [...inputToUnregister, `questions[${item.id}]`];
        break;
    }
  });
  callBack(inputToUnregister);
};

export const getFirstLevelQuestions = (questionsAll, callBack) => {
  const firstLevelQuestionsWithDuplicate = questionsAll.filter(
    (ques) =>
      ques.level === 0 &&
      ques.parentSurveyFormQuestion === null &&
      ques.surveyFormQuestion.category !== null &&
      (ques.surveyFormQuestion.formAnswers.length > 0 ||
        ques.surveyFormQuestion.dateTimeTypeAnswer !== null ||
        ques.surveyFormQuestion.fileTypeAnswer !== null ||
        ques.surveyFormQuestion.sliderLabelMax !== null ||
        ques.surveyFormQuestion.type === 'textarea' ||
        ques.surveyFormQuestion.sliderLabelMin !== null)
  );
  const firstLevelQuestions = Array.from(new Set(firstLevelQuestionsWithDuplicate));
  console.log(firstLevelQuestions.length);
  firstLevelQuestions.sort((a, b) => parseFloat(a.rank) - parseFloat(b.rank));
  return firstLevelQuestions;
};

export const getSurveyPopupFirstLevelQuestions = (questionsAll, callBack) => {
  const firstLevelQuestionsWithDuplicate = questionsAll.filter(
    (ques) =>
      ques.level === 0 &&
      ques.parentSurveyFormQuestion === null &&
      (ques.surveyFormQuestion.formAnswers.length > 0 ||
        ques.surveyFormQuestion.dateTimeTypeAnswer !== null ||
        ques.surveyFormQuestion.fileTypeAnswer !== null ||
        ques.surveyFormQuestion.sliderLabelMax !== null ||
        ques.surveyFormQuestion.type === 'textarea' ||
        ques.surveyFormQuestion.sliderLabelMin !== null)
  );
  const firstLevelQuestions = Array.from(new Set(firstLevelQuestionsWithDuplicate));
  console.log(firstLevelQuestions.length);
  firstLevelQuestions.sort((a, b) => parseFloat(a.rank) - parseFloat(b.rank));
  return firstLevelQuestions;
};
