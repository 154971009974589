import React, { useEffect, useState } from 'react';
import { FormControl, Typography, Paper } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DraggableList from './DraggableList';
import { reorder } from './helpers';
import QuestionText from './questionText';
import { Controller } from 'react-hook-form';

const useStyles = makeStyles({
  flexPaper: {
    flex: 1,
    minWidth: 350
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  }
});

export const RankingInput = ({ id, name, infoText, control, value, required, level, questionData, currentQuestionIndex, handleChange, answeredData }) => {
  const classes = useStyles();
  let answeredItem = [];
  if (answeredData && answeredData?.formAnswers.length > 0) {
    answeredItem = answeredData?.formAnswers;
  } else {
    answeredItem = value ? value : questionData.formAnswers;
  }
  const [items, setItems] = useState([]);

  const onDragEnd = ({ destination, source }) => {
    if (!destination) return;
    const newItems = reorder(items.length > 0 ? items : answeredItem, source.index, destination.index);
    setItems(newItems);
    return newItems;
  };

  useEffect(() => {
    if (items.length > 0) {
      console.log(items);
      handleChange({ id: id, surveyFormQuestionId: questionData.id, level: level, value: items });
    }
  }, [items]);

  return (
    <FormControl component="fieldset" style={{ justifyContent: 'space-between' }} fullWidth>
      <Typography variant="h6" style={{ alignItems: 'center' }}>
        <QuestionText surveyFormQuestion={questionData} level={level} infoText={infoText} currentQuesIndex={currentQuestionIndex} />
      </Typography>
      <div className={classes.root}>
        <Paper elevation={0} className={classes.flexPaper}>
          <Controller
            name={name}
            render={({ field: { onChange: onCheckChange }, fieldState: { error }, formState }) => (
              <DraggableList
                items={items.length > 0 ? items : answeredItem}
                onDragEnd={(data) => {
                  onCheckChange(onDragEnd(data));
                }}
              />
            )}
            control={control}
          />
        </Paper>
      </div>
    </FormControl>
  );
};
