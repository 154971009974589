import React from 'react';
import { ClipboardDocumentCheckIcon, HeartIcon, MegaphoneIcon, MapPinIcon, PuzzlePieceIcon, EllipsisHorizontalCircleIcon } from '@heroicons/react/20/solid';
import { Link as RouteLink } from 'react-router-dom';

import bindClassNames from 'classnames/bind';
import styles from './index.module.scss';
const cx = bindClassNames.bind(styles);

const Products = () => {
  return (
    <div className={cx('wrapper')}>
      {ProductsContent.map((item, index) => (
        <RouteLink to={`/dashboard/products/${item.slug}`} key={index}>
          <div className={cx('card')} key={index}>
            <div className={cx('icon_background')}>{item.icon}</div>
            <dvi className={cx('heading')}>
              <h3>{item.title}</h3>
            </dvi>
          </div>
        </RouteLink>
      ))}
    </div>
  );
};

export default Products;

const ProductsContent = [
  {
    id: 1,
    icon: <ClipboardDocumentCheckIcon className={cx('icon')} aria-hidden="true" />,
    title: 'Organizational Systems Audit'
  },
  {
    id: 2,
    icon: <HeartIcon className={cx('icon')} aria-hidden="true" />,
    title: 'Employee Sentiment Assessment',
    slug: 'employee-sentiment-assessment'
  },
  {
    id: 3,
    icon: <MegaphoneIcon className={cx('icon')} aria-hidden="true" />,
    title: 'Employee Pulse',
    slug: 'employee-pulse'
  },
  {
    id: 4,
    icon: <MapPinIcon className={cx('icon')} aria-hidden="true" />,
    title: 'Learning Journey',
    slug: 'learning-journey'
  },
  {
    id: 5,
    icon: <PuzzlePieceIcon className={cx('icon')} aria-hidden="true" />,
    title: 'Managed Services',
    slug: 'managed-services'
  },
  {
    id: 6,
    icon: <EllipsisHorizontalCircleIcon className={cx('icon')} aria-hidden="true" />,
    title: 'Other',
    slug: 'other'
  }
];
