import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './store';
import './index.scss';
import App from './App';
import { MuiThemeProvider } from '@material-ui/core';
import theme from './layout/theme';
import { SurveyCampaignProvider } from './contexts/SurveyCampaignContext';

import reportWebVitals from './reportWebVitals';
const el = document.getElementById('root');
const root = createRoot(el);

root.render(
  <ReduxProvider store={store}>
    <MuiThemeProvider theme={theme}>
      <SurveyCampaignProvider>
        <App />
      </SurveyCampaignProvider>
    </MuiThemeProvider>
  </ReduxProvider>
);

reportWebVitals();
