import { Typography } from '@material-ui/core';
import { TextField, FormControl, FormGroup } from '@material-ui/core';
import { useContext } from 'react';
import { Controller } from 'react-hook-form';
import { LanguageContext } from '../../contexts/LanguageContext';

const MloeaTextInput = ({ id, label, name, control, questionData, currentQuestionIndex, required, answerText, handleChange, ...rest }) => {
  const { dictionary } = useContext(LanguageContext);

  return (
    <FormControl component="fieldset" fullWidth>
      <Typography variant="h6" style={{ alignItems: 'center' }}>
        {label}:
      </Typography>
      <FormGroup>
        <Controller
          name={name}
          control={control}
          defaultValue={answerText ? answerText : ''}
          render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
            <TextField
              size="small"
              onChange={onChange}
              value={value}
              fullWidth
              multiline
              error={!!error}
              helperText={error ? error.message : null}
              variant="outlined"
              onBlur={(e) => {
                handleChange({ value: e.target.value });
              }}
            />
          )}
          rules={{
            required: {
              value: required,
              message: dictionary.landingPage.form.requiredField
            }
          }}
        />
      </FormGroup>
    </FormControl>
  );
};

export default MloeaTextInput;
