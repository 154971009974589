import { createContext, useEffect, useState } from 'react';
const SurveyCampaignContext = createContext();

// eslint-disable-next-line react/prop-types
export function SurveyCampaignProvider({ children }) {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [questionsAll, setQuestionsAll] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [selectedAnswer2ndLevel, setSelectedAnswer2ndLevel] = useState([]);
  const [selectedAnswer3rdLevel, setSelectedAnswer3rdLevel] = useState([]);
  const [selectedAnswer4rthLevel, setSelectedAnswer4rthLevel] = useState([]);
  const [categories, setCategories] = useState([]);
  const [unattendedQuestions, setUnattendedQuestions] = useState([]);
  const [currentElement, setCurrentElement] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [assessmentUser, setAssessmentUser] = useState(null);

  useEffect(() => {
    setSelectedAnswer(null);
  }, [currentQuestion]);

  useEffect(() => {
    setSelectedAnswer2ndLevel([]);
    setSelectedAnswer3rdLevel([]);
    setSelectedAnswer4rthLevel([]);
  }, [questionsAll]);

  return (
    <SurveyCampaignContext.Provider
      value={{
        selectedAnswer,
        setSelectedAnswer,
        currentQuestion,
        setCurrentQuestion,
        questionsAll,
        setQuestionsAll,
        selectedAnswer2ndLevel,
        setSelectedAnswer2ndLevel,
        selectedAnswer3rdLevel,
        setSelectedAnswer3rdLevel,
        selectedAnswer4rthLevel,
        setSelectedAnswer4rthLevel,
        unattendedQuestions,
        setUnattendedQuestions,
        currentElement,
        setCurrentElement,
        categories,
        setCategories,
        answers,
        setAnswers,
        assessmentUser,
        setAssessmentUser
      }}>
      {children}
    </SurveyCampaignContext.Provider>
  );
}

export default SurveyCampaignContext;
