import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';
import red from '@material-ui/core/colors/red';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3A6CC8'
    },
    secondary: pink,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2
  },
  sidebar: {
    width: 280,
    closedWidth: 55
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['Noto Sans', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(','),
    fontSize: 12
  },
  overrides: {
    MuiAppBar: {
      colorSecondary: {
        color: 'white',
        backgroundColor: '#FCC753'
      }
    },
    MuiContainer: {
      root: {
        paddingBottom: 20
      }
    },
    MuiButtonBase: {
      root: {
        '&:hover:active::after': {
          // recreate a static ripple color
          // use the currentColor to make it work both for outlined and contained buttons
          // but to dim the background without dimming the text,
          // put another element on top with a limited opacity
          content: '""',
          display: 'block',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          right: 0,
          backgroundColor: 'currentColor',
          opacity: 0.3,
          borderRadius: 'inherit'
        }
      }
    },
    MuiFormLabel: {
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131'
        }
      }
    },
    MuiFormGroup: {
      root: {
        paddingLeft: 20,
        paddingRight: 40,
        paddingTop: 5
      }
    },
    MuiCardMedia: {
      root: {
        backgroundSize: 'auto'
      }
    }
  },
  props: {
    MuiButtonBase: {
      // disable ripple for perf reasons
      disableRipple: true
    }
  }
});

export default responsiveFontSizes(theme);
