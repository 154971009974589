import { FormLabel, Typography } from '@material-ui/core';
import QuestionText from './questionText';
import RadioMatrixInput from './radioMatrixInput';
import { Controller } from 'react-hook-form';
import { LanguageContext } from '../../contexts/LanguageContext';
import { useContext } from 'react';

const matrixLabels = [
  { label: 'Strongly Agree', value: 'Strongly Agree' },
  { label: 'Agree', value: 'Agree' },
  { label: 'Neutral', value: 'Neutral' },
  { label: 'Disagree', value: 'Disagree' },
  { label: 'Strongly Disagree', value: 'Strongly Disagree' }
];

const MatrixInput = ({ id, name, control, infoText, required, level, questionData, currentQuestionIndex, handleChange, answeredData }) => {
  const { dictionary } = useContext(LanguageContext);
  questionData.formAnswers.sort((a, b) => parseFloat(a.rank) - parseFloat(b.rank));
  return (
    <div style={{ justifyContent: 'space-between', width: '100%' }}>
      <Typography variant="h6" style={{ alignItems: 'center' }}>
        <QuestionText surveyFormQuestion={questionData} level={level} infoText={infoText} currentQuesIndex={currentQuestionIndex} />
      </Typography>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
        <FormLabel component="div" style={{ width: '25%' }}>
          #
        </FormLabel>
        <div style={{ paddingLeft: 10, display: 'flex', flexWrap: 'wrap', flexDirection: 'column', width: '75%' }}>
          <div style={{ paddingLeft: 10, display: 'flex', flexWrap: 'wrap', flexDirection: 'row', width: '100%' }}>
            {dictionary.surveyPopup.matrixLabels.map((item) => (
              <div style={{ display: 'inline-flex', alignItems: 'center', marginRight: '56px' }} key={item.label}>
                {item.label}
              </div>
            ))}
          </div>
        </div>
      </div>
      {questionData.formAnswers.map((option, index) => {
        const answer = answeredData ? answeredData?.formAnswers.find((item) => item?.id === option.id) : null;
        return (
          <div key={`${name}-${index}-${option['@id']}`}>
            <Controller
              name={`${name}[${index}].id`}
              control={control}
              defaultValue={option.id}
              render={({ field: { onChange, value }, fieldState: { error }, formState }) => <input key={`${name}-${index}-${option['@id']}`} name={name} value={value} type="hidden" />}
            />
            <RadioMatrixInput name={`${name}[${index}].value`} control={control} required={required} answeredOption={answer} key={option['@id']} label={option.value} options={dictionary.surveyPopup.matrixLabels} handleChange={handleChange} />
          </div>
        );
      })}
    </div>
  );
};

export default MatrixInput;
