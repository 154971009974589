import React from 'react';
import Lottie from 'react-lottie';
import ShapesLottie from '../../assets/lotties/shapes-background.json';
import ScrollDownLottie from '../../assets/lotties/scroll-down.json';

import bindClassNames from 'classnames/bind';
import styles from './index.module.scss';
const cx = bindClassNames.bind(styles);

const Home = () => {
  return (
    <div className={cx('wrapper')}>
      <div className={cx('content')}>
        <div className={cx('left')}>
          <h3 className={cx('heading')}>
            Improve inclusion and equity in your workplace with <br />
            <span className={cx(['heading', 'strong'])}>Holistic DEI Assessments, Benchmarking, and Solutions.</span>
          </h3>

          <div className="flex justify-start">
            <button className="bg-[#2F435B] hover:bg-opacity-90 text-gray-100 transition ease-in duration-300 font-bold py-2 px-4 rounded-md mt-4">Schedule a Demo</button>
            <button className="bg-transparent hover:bg-[#2F435B] text-gray-700 font-semibold hover:text-gray-100 py-2 px-4 border border-[#2F435B] hover:border-transparent transition duration-300 ease-in rounded-md mt-4 ml-4">Learn More</button>
          </div>
        </div>

        <div className={cx('right')}>
          <div className={cx('lottie_wrapper')}>
            <Lottie
              isClickToPauseDisabled={true}
              options={{
                loop: true,
                autoplay: true,
                animationData: ShapesLottie,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }
              }}
            />
          </div>
        </div>
      </div>

      <div className={cx('scroll')}>
        <p>Scroll to explore</p>

        <div className={cx('lottie_wrapper')}>
          <Lottie
            isClickToPauseDisabled={true}
            options={{
              loop: true,
              autoplay: true,
              animationData: ScrollDownLottie,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
