import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const MIME_TYPE = 'application/ld+json';
const ENTRYPOINT = process.env.REACT_APP_API_SERVER || '';
let lang = localStorage.getItem('rcml-lang');
let token = localStorage.getItem('token');

const gendersApi = createApi({
  reducerPath: 'genders',
  baseQuery: fetchBaseQuery({
    baseUrl: ENTRYPOINT,
    prepareHeaders: (headers, { state }) => {
      headers.set('X-LOCALE', lang || 'en');
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      if (null === headers.get('Accept')) headers.set('Accept', MIME_TYPE);
    }
  }),
  endpoints(builder) {
    return {
      fetchGenders: builder.query({
        query: () => {
          return {
            url: `gender/list`,
            method: 'GET'
          };
        }
      })
    };
  }
});
export const { useFetchGendersQuery } = gendersApi;
export { gendersApi };
