import React, { useState } from 'react';
import PropTypes from 'prop-types';

import bindClassNames from 'classnames/bind';
import styles from './index.module.scss';
const cx = bindClassNames.bind(styles);

import { Snackbar, SnackbarContent } from '@mui/material';
import useAuth from '../../contexts/useAuth';
import { Link as RouterLink } from 'react-router-dom';

const Login = () => {
  const { loginUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (email === '' || password === '') {
      setError({
        email: 'Email is required',
        password: 'Password is required'
      });
      setLoading(false);
      setOpen(true);
    } else {
      const auth = {
        username: email,
        password
      };
      await loginUser(auth).catch((error) => {
        setLoading(false);
        setIsError(error.message);
        setOpen(true);
      });
    }
  };

  return (
    <div className={cx('wrapper')}>
      <div className={cx('content')}>
        <div className={cx('heading_content')}>
          <h3 className={cx('heading')}>Log In to Kanarys</h3>
          <p>Log in to improve inclusion and equity in your workplace with Holistic DEI Assessments, Benchmarking, and Solutions.</p>
        </div>

        <form className={cx('form')}>
          <div>
            <label htmlFor="email" className={cx('label')}>
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              autoComplete="email"
              placeholder="Email"
              className={cx('inputs')}
              onChange={(e) => {
                setEmail(e.target.value);
                setError({
                  email: ''
                });
              }}
            />
            {error.email && <p className={cx('error')}>{error.email}</p>}
          </div>
          <div>
            <label htmlFor="password" className={cx('label')}>
              Password
            </label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              className={cx('inputs')}
              onChange={(e) => {
                setPassword(e.target.value);
                setError({
                  password: ''
                });
              }}
            />
            {error.password && <span className={cx('error')}>{error.password}</span>}
          </div>
          <div>
            <div className={cx(['col', 'between'])}>
              <div className={cx(['col'])}>
                <input id="remember_me" name="remember_me" type="checkbox" className={cx('remember')} />
                <label htmlFor="remember_me" className={cx('remember_label')}>
                  Remember me
                </label>
              </div>

              <RouterLink to="#" className={cx('forgot')}>
                Forgot your password?
              </RouterLink>
            </div>
          </div>
          <div>
            <button className={cx('button')} onClick={(e) => handleSubmit(e)} type="submit">
              Log in
            </button>
          </div>
        </form>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <SnackbarContent className={cx('popup')} message={isError ? isError : 'Please fill all the fields'} />
      </Snackbar>
    </div>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string
};

export default Login;
