import React, { Fragment, useContext } from 'react';
import { Controller } from 'react-hook-form';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import { languageOptions } from '../../../languages';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { FormHelperText } from '@material-ui/core';

import bindClassNames from 'classnames/bind';
import styles from './index.module.scss';
import useComponentVisible from '../../../hooks/withClickOutside';
const cx = bindClassNames.bind(styles);

export default function LanguageSelector({ name, label = 'language', control, required }) {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  const { dictionary } = useContext(LanguageContext);

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={userLanguage ? userLanguage : 'en'}
      render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
        <div className={cx('root-wrapper')} ref={ref}>
          <label htmlFor="" className={cx('label')}>
            {label}
          </label>
          <Listbox
            value={value}
            onChange={(event) => {
              onChange(event);
              userLanguageChange(event);
            }}>
            <div className={cx('wrapper')}>
              <Listbox.Button className={cx('button')} onClick={() => setIsComponentVisible(!isComponentVisible)}>
                <span className={cx('language')}>{userLanguage ? languageOptions[userLanguage] : languageOptions['en']}</span>
                <span className={cx('icon-wrapper')}>
                  <ChevronDownIcon className={cx(['icon', { 'icon-rotate': isComponentVisible }])} aria-hidden="true" />
                </span>
              </Listbox.Button>
              <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                <Listbox.Options className={cx('options')} onClick={() => setIsComponentVisible(false)}>
                  {Object.entries(languageOptions).map(([id, name]) => (
                    <Listbox.Option key={id} value={id} className={cx('language-wrapper')}>
                      <span className={cx('language')}>{name}</span>
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
          <FormHelperText error={!!error}>{error ? error.message : null}</FormHelperText>
        </div>
      )}
      rules={{
        required: {
          value: required,
          message: dictionary.landingPage.form.requiredField
        }
      }}
    />
  );
}
