import { Controller } from 'react-hook-form';
import { Fragment } from 'react';

import bindClassNames from 'classnames/bind';
import styles from './index.module.scss';
const cx = bindClassNames.bind(styles);

const CommonTextInput = ({ label, name, infoText, control, required, type, placeholder, tooltip, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={''}
      render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
        <div className={cx('root-wrapper')}>
          <label htmlFor={placeholder ? placeholder : label} className={cx('label')}>
            {label}
            {tooltip && <span className={cx('label-spacer')}>{tooltip}</span>}
          </label>
          <input type="text" onChange={onChange} value={value} id={placeholder ? placeholder : label} className={cx('input')} placeholder={placeholder ? placeholder : label} />
          {!!error && <p className={cx('error')}>{error ? error.message : null}</p>}
        </div>
      )}
      rules={required}
      {...props}
    />
  );
};

export default CommonTextInput;
