import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import useFindUser from './contexts/useFindUser';
import { UserContext } from './contexts/UserContext';
import routes from './routes';
const App = () => {
  const { user, setUser, isLoading } = useFindUser();

  return (
    <Router>
      <UserContext.Provider value={{ user, setUser, isLoading }}>{routes}</UserContext.Provider>
    </Router>
  );
};

export default App;
