import { useContext } from 'react';
import KanarysLogo from '../../../assets/images/ki4uQJtG-image.png';
import { Link as RouterLink } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContext';

import bindClassNames from 'classnames/bind';
import styles from './index.module.scss';
const cx = bindClassNames.bind(styles);

function Footer() {
  const { user } = useContext(UserContext);

  return (
    <footer className={cx('wrapper')}>
      <section className={cx('footer_upper')}>
        <div>
          <h3>Discover</h3>
          <ul className={cx('upper_items')}>
            <li>
              <RouterLink to="/about">About Us</RouterLink>
            </li>
            <li>
              <RouterLink to="#">Features</RouterLink>
            </li>
            <li>
              <RouterLink to="#">Resources</RouterLink>
            </li>
            <li>
              <RouterLink to="#">Blog</RouterLink>
            </li>
            <li>
              <RouterLink to="#">News</RouterLink>
            </li>
          </ul>
        </div>

        <div>
          <h3>Learn More</h3>
          <ul className={cx('upper_items')}>
            <li>
              <RouterLink to="#">For Employees</RouterLink>
            </li>
            <li>
              <RouterLink to="#">Product</RouterLink>
            </li>
            <li>
              <RouterLink to="#">Schedule a Demo</RouterLink>
            </li>
            <li>
              <RouterLink to="#">Contact Us</RouterLink>
            </li>
          </ul>
        </div>

        <div role="img" aria-label="Kanarys Logo" className={cx('image')}>
          <img src={KanarysLogo} alt="Kanarys Logo" className={cx('image')} />
        </div>
      </section>

      <section className={cx('footer_lower')}>
        <div>
          <button
            className={cx('client_login', {
              logged_in: user
            })}>
            Client Login
          </button>
        </div>

        <div className={cx('right')}>
          <ul className={cx('items')}>
            <li>&copy; {new Intl.DateTimeFormat('en-US', { year: 'numeric' }).format(new Date())}</li>
            <li>
              <RouterLink to="/privacy-policy">Privacy Policy</RouterLink>
            </li>
            <li>
              <RouterLink to="/terms-of-use">Terms of Use</RouterLink>
            </li>
            <li>
              <RouterLink to="#">Contact Us</RouterLink>
            </li>
          </ul>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
