import 'date-fns';
import React, { useState, useContext } from 'react';
import { Typography, Grid, FormGroup, FormControl } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import QuestionText from './questionText';
import { Controller } from 'react-hook-form';
import { LanguageContext } from '../../contexts/LanguageContext';
import SurveyCampaignContext from '../../contexts/SurveyCampaignContext';

export const DateTimeInput = ({ id, name, infoText, control, required, level, questionData, currentQuestionIndex, handleChange, answeredData }) => {
  const { dictionary } = useContext(LanguageContext);
  const { setCurrentElement } = useContext(SurveyCampaignContext);
  const [selectedTime, setSelectedTime] = useState(answeredData && answeredData?.formAnswers?.time ? new Date(answeredData?.formAnswers?.time) : new Date());
  const [selectedDate, setSelectedDate] = useState(answeredData && answeredData?.formAnswers?.date ? new Date(answeredData?.formAnswers?.date) : new Date());

  return (
    <FormControl component="fieldset" fullWidth>
      <Typography variant="h6">
        <QuestionText surveyFormQuestion={questionData} level={level} infoText={infoText} currentQuesIndex={currentQuestionIndex} />
      </Typography>
      <FormGroup>
        <Controller name={`${name}.id`} control={control} defaultValue={questionData.dateTimeTypeAnswer.id} render={({ field: { onChange, value }, fieldState: { error }, formState }) => <input name={name} value={value} type="hidden" />} />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {questionData.dateTimeTypeAnswer.dateTime.map((item) => {
            if (item === 'time') {
              return (
                <Grid container key={`${id}_${item}_0`}>
                  <Controller
                    name={`${name}.${item}`}
                    control={control}
                    defaultValue={selectedTime}
                    render={({ field: { onChange, ref, ...rest } }) => (
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        label={dictionary.surveyPopup.time}
                        value={selectedTime}
                        onChange={(value) => {
                          onChange(value);
                          setSelectedTime(value);
                          handleChange({ id: id, surveyFormQuestionId: questionData.id, level: level, value: value });
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change time'
                        }}
                        {...rest}
                      />
                    )}
                    rules={required}
                  />
                </Grid>
              );
            } else {
              return (
                <Grid container key={`${id}_${item}_0`}>
                  <Controller
                    name={`${name}.${item}`}
                    control={control}
                    defaultValue={answeredData && answeredData?.formAnswers?.time ? new Date(answeredData?.formAnswers?.time) : new Date()}
                    render={({ field: { onChange, ref, ...rest } }) => (
                      <KeyboardDatePicker
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label={dictionary.surveyPopup.date}
                        value={selectedDate}
                        onChange={(value) => {
                          onChange(value);
                          setSelectedDate(value);
                          handleChange({ id: id, surveyFormQuestionId: questionData.id, level: level, value: value });
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                        {...rest}
                      />
                    )}
                    rules={required}
                  />
                </Grid>
              );
            }
          })}
        </MuiPickersUtilsProvider>
      </FormGroup>
    </FormControl>
  );
};
