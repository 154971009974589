import React from 'react';
import Lottie from 'react-lottie';
import PrivacyLottie from '../../assets/lotties/privacy.json';
import KanarysLogo from '../../assets/images/kanarys-logo.png';

import bindClassNames from 'classnames/bind';
import styles from './index.module.scss';
import { useCmsPageQuery } from '../../store';
import { Backdrop, CircularProgress } from '@material-ui/core';
import Errors from '../Errors';
const cx = bindClassNames.bind(styles);

const PrivacyPolicy = () => {
  const { data, error, isLoading } = useCmsPageQuery(1);

  if (isLoading) {
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else if (error) {
    return <Errors error={error} />;
  } else {
    return (
      <div className={cx('gradient')}>
        <div className={cx('wrapper')}>
          <div className={cx('privacy_head')}>
            <img src={KanarysLogo} alt="Kanarys Logo" className="h-14" />
            <h1 className={cx('head')}>Privacy Policy</h1>
            <span>
              <p className={cx('span')}>
                Last updated:{' '}
                {data &&
                  data.updatedAt &&
                  new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit'
                  }).format(new Date(data.updatedAt))}
              </p>
            </span>
            <Lottie
              isClickToPauseDisabled={true}
              options={{
                loop: true,
                autoplay: true,
                animationData: PrivacyLottie,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }
              }}
              style={{
                width: '50%',
                height: '50%',
                position: 'relative'
              }}
            />
          </div>

          <section className={cx('privacy_body')}>
            <aside className={cx('aside')}>
              <ul className={cx('items')}>
                {data.pageSections.map((item) => (
                  <li key={item.id} className={cx('item')}>
                    <a href={`#${item.id}`} className={cx('link')}>
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            </aside>

            <main className={cx('main')}>
              {data.pageSections.map((item) => (
                <article key={item.id}>
                  <h2 id={item.id} className={cx('content_head')}>
                    {item.title}
                  </h2>
                  <div className={cx('content')} dangerouslySetInnerHTML={{ __html: item.content }} />
                </article>
              ))}
            </main>
          </section>
        </div>
      </div>
    );
  }
};

export default PrivacyPolicy;
