import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from './UserContext.js';
// eslint-disable-next-line no-undef
const SERVERURL = process.env.REACT_APP_API_SERVER;

export default function useAuth() {
  let history = useHistory();
  const { setUser } = useContext(UserContext);
  const [error, setError] = useState(null);

  const setUserContext = async () => {
    const request = new Request(`${SERVERURL}/user/me`, {
      method: 'GET',
      headers: new Headers(localStorage.getItem('token') ? { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } : { 'Content-Type': 'application/json' })
    });
    return await fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }

        return response.json();
      })
      .then(async (res) => {
        setUser(res);
        history.push('/home');
      });
  };

  //register user
  const registerUser = async ({ username, email, password, passwordConfirm }) => {
    const request = new Request(`${SERVERURL}/login_check`, {
      method: 'POST',
      body: JSON.stringify({ username, email, password, passwordConfirm }),
      headers: new Headers({ 'Content-Type': 'application/json' })
    });
    return await fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          setError(response.statusText);
          throw new Error(response.statusText);
        }

        return response.json();
      })
      .then(async ({ token }) => {
        localStorage.setItem('token', token);
        await setUserContext();
      });
  };

  //login user
  const loginUser = async ({ username, password }) => {
    const request = new Request(`${SERVERURL}/login_check`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' })
    });

    return await fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(async ({ token }) => {
        localStorage.setItem('token', token);
        await setUserContext();
      });
  };

  const logout = () => {
    localStorage.removeItem('token');
    return Promise.resolve();
  };

  return {
    registerUser,
    loginUser,
    logout,
    error
  };
}
