import { Typography } from '@material-ui/core';
import { TextField, FormControl, FormGroup } from '@material-ui/core';
import QuestionText from './questionText';
import { Controller } from 'react-hook-form';
import { useContext } from 'react';
import SurveyCampaignContext from '../../contexts/SurveyCampaignContext';

const TextInput = ({ id, label, name, infoText, control, required, level, questionData, currentQuestionIndex, handleChange, answeredData }) => {
  const { setCurrentElement } = useContext(SurveyCampaignContext);
  return (
    <FormControl component="fieldset" fullWidth>
      <Typography variant="h6" style={{ alignItems: 'center' }}>
        {<QuestionText surveyFormQuestion={questionData} infoText={infoText} level={level} currentQuesIndex={currentQuestionIndex} />}
      </Typography>
      <FormGroup>
        <Controller
          name={name}
          control={control}
          defaultValue={answeredData ? answeredData?.formAnswers : ''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              size="small"
              type={'text'}
              onChange={onChange}
              onBlur={(e) => {
                setCurrentElement(e.target.parentElement.parentElement.parentElement.parentElement.parentElement);
                handleChange({ id: id, surveyFormQuestionId: questionData.id, level: level, value: e.target.value });
              }}
              value={value}
              placeholder={questionData.placeholder}
              fullWidth
              multiline={true}
              label={label}
              error={!!error}
              helperText={error ? error.message : null}
              variant="outlined"
            />
          )}
          rules={required}
        />
      </FormGroup>
    </FormControl>
  );
};

export default TextInput;
