import React, { useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText } from '@material-ui/core';
import QuestionText from './questionText';
import { Controller } from 'react-hook-form';
import { styled } from '@material-ui/core/styles';
import useSurveyCampaignContext from '../../hooks/use-survey-campaign-context';

const FormControlLabelStyled = styled(FormControlLabel)({
  '& .MuiFormControlLabel-label': {
    width: '100%',
    display: 'flex',
    border: '1px solid #E6E6E6',
    margin: '5px 0px 5px 0px',
    borderRadius: '5px',
    cursor: 'pointer'
  },
  '&.Mui-checked.MuiFormControlLabel-label': {
    backgroundColor: '#F5F5F5'
  }
});

const CheckboxInput = ({ id, name, infoText, control, required, level, maxSelection, questionData, value, currentQuestionIndex, handleChange, answeredData }) => {
  const { setCurrentElement } = useSurveyCampaignContext();

  let fldVal = [];
  if (value && value.length > 0) {
    fldVal = value;
  } else if (answeredData?.formAnswers.length > 0) {
    fldVal = answeredData?.formAnswers;
  }
  const [selectedItems, setSelectedItems] = useState(fldVal);
  const [disabledItems, setDisabledItems] = useState([]);
  //questionData.formAnswers.sort((a, b) => parseFloat(a.rank) - parseFloat(b.rank));

  const ifNoneOrNoneOfAbove = (formAnswersItems) => formAnswersItems.find((item) => ['NONE OF THE ABOVE', 'NONE'].includes(item.value.toUpperCase()));

  const handleSelect = (value) => {
    const newValues = selectedItems?.some((item) => item.id === value.id) ? selectedItems?.filter((item) => item.id !== value.id) : [...(selectedItems ?? []), value];
    setSelectedItems(newValues);
    return newValues;
  };

  useEffect(() => {
    setDisabledItems([]);
    handleChange({ id: id, surveyFormQuestionId: questionData.id, level: level, value: selectedItems });
    if (selectedItems.length > 0) {
      const noneAndNoneOfAboveItem = ifNoneOrNoneOfAbove(questionData.formAnswers);
      const isSelectedItemIsNoneOfAbove = ifNoneOrNoneOfAbove(selectedItems);

      if (selectedItems.length === 1 && noneAndNoneOfAboveItem && isSelectedItemIsNoneOfAbove && noneAndNoneOfAboveItem?.id === isSelectedItemIsNoneOfAbove?.id) {
        setDisabledItems(questionData.formAnswers.filter((item) => item.id !== noneAndNoneOfAboveItem.id));
      }

      if (selectedItems.length === maxSelection) {
        setDisabledItems(questionData.formAnswers.filter((item) => !selectedItems.some((e) => e.id === item.id)));
      }

      if (noneAndNoneOfAboveItem && selectedItems.filter((item) => item.id !== noneAndNoneOfAboveItem.id).length > 0) {
        setDisabledItems((preState) => [...preState, noneAndNoneOfAboveItem]);
      }
    }
  }, [selectedItems]);

  useEffect(() => {
    if (answeredData) {
      setSelectedItems(answeredData?.formAnswers);
    }
  }, [answeredData]);

  return (
    <FormControl component="fieldset" fullWidth>
      <QuestionText surveyFormQuestion={questionData} level={level} infoText={infoText} currentQuesIndex={currentQuestionIndex} />
      <Controller
        name={name}
        defaultValue={answeredData ? answeredData?.formAnswers : ''}
        render={({ field: { onChange: onCheckChange }, fieldState: { error }, formState }) => (
          <React.Fragment>
            <FormGroup className="grid grid-cols-3 gap-4 ">
              {questionData.formAnswers.map((option) => {
                return (
                  <FormControlLabelStyled
                    control={
                      <Checkbox
                        color="primary"
                        className="opacity-0"
                        id={option.id.toString()}
                        disabled={disabledItems.includes(option)}
                        checked={selectedItems.findIndex((item) => item.id === option.id) !== -1}
                        onChange={(e) => {
                          onCheckChange(handleSelect(option));
                          setCurrentElement(e.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement);
                        }}
                      />
                    }
                    label={
                      <p
                        className="w-full p-4 rounded-md border-primary"
                        style={{
                          backgroundColor: selectedItems.findIndex((item) => item.id === option.id) !== -1 ? '#EBF0FA' : '',
                          border: selectedItems.findIndex((item) => item.id === option.id) !== -1 ? '2px solid #3A6CC8' : '',
                          borderRadius: selectedItems.findIndex((item) => item.id === option.id) !== -1 ? '5px' : ''
                        }}>
                        {option.value}
                      </p>
                    }
                    key={option.id}
                  />
                );
              })}
            </FormGroup>

            <div className="ml-12">
              <FormHelperText error={!!error}>{error ? error.message : null}</FormHelperText>
            </div>
          </React.Fragment>
        )}
        control={control}
        rules={required}
      />
    </FormControl>
  );
};

export default CheckboxInput;
